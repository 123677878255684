import api from 'api'
import Logo from 'components/layout/Logo'
import { subscriptionPlans } from 'constants/subscriptions'
import { FC, useCallback, useState } from 'react'

export const planFeatures = {
  all_features: [
    'Unlimited Number Of UPCs Scanned',
    'Unlimited Number Of Suppliers Uploaded',
    'Unlimited Filtering Capabilities',
    'Saves Adjustment Of Product Bundle Size',
    'Add Items Directly Into Working Purchase Orders',
    'Restock All Inventory In Seconds',
    'Create A Watchlist Of ASINs To Buy',
    'Inventory Planning Dashboard & Management',
    'Supplier Leads & Account Management',
    'Employee Product Tracker',
    'Download Purchase Orders & Send To Suppliers',
    'Download Purchase Order & Upload Into Shipping Softwares',
  ],
  scraper: ['Unlimited Number Of UPCs Scanned', 'Unlimited Number Of Suppliers Uploaded', 'Unlimited Filtering Capabilities', 'Saves Adjustment Of Product Bundle Size'],
}

const PlansSelectPage: FC = () => {
  const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly')
  const [submitting, setSubmitting] = useState(false)

  const submit = useCallback(
    async (plan: string, period: 'monthly' | 'yearly') => {
      if (submitting) return
      setSubmitting(true)
      await api
        .selectPlan(plan, period)
        .then((url) => {
          if (url) {
            window.open(url, '_self')
          }
        })
        .finally(() => setSubmitting(false))
    },
    [submitting]
  )

  return (
    <div className="w-full h-full flex items-center justify-center overflow-hidden">
      <div className="flex flex-col gap-4 h-full w-full items-center overflow-y-auto py-8">
        <div className="flex-cool items-start w-max bg-white rounded-xl drop-shadow-xl">
          <Logo className="w-40 [&>img]:!object-contain self-center pt-4" />
          <div className="h-max w-80 grid grid-cols-2 shrink gap-2 p-4">
            <button onClick={() => setPeriod('monthly')} className={period === 'monthly' ? 'button-primary h-min' : 'button-secondary h-min'}>
              Monthly
            </button>
            <button onClick={() => setPeriod('yearly')} className={period === 'yearly' ? 'button-primary h-min' : 'button-secondary h-min'}>
              Yearly
            </button>
          </div>
          <div className="grid xl:grid-cols-2 gap-4 px-4 pb-4 w-full">
            {subscriptionPlans.map((plan) => (
              <div key={plan.name} className="bg-white border border-gray-200 flex flex-col gap-1 grow rounded-xl drop-shadow-lg h-max">
                <div className="flex flex-col gap-0 p-4 bg-brand-primary text-center rounded-t-xl">
                  <h2 className="text-white text-3xl font-bold">{plan.name}</h2>
                  <p className="text-white text-xl font-semibold">{plan.isLimited ? 'Limited' : 'Unlimited'}</p>
                </div>
                <div className="flex items-end p-4 justify-center border-b border-gray-200">
                  <span className="text-text-primary bottom-7 font-medium text-xl relative mr-1">$</span>
                  <span className="text-brand-primary text-5xl font-semibold">{period === 'monthly' ? plan.price.monthly : plan.price.yearly}</span>
                  <span className="text-text-primary  font-medium text-xl">{period === 'monthly' ? ' / Monthly' : ' / Yearly'}</span>
                </div>
                <ul className="p-4 font-medium text-text-primary text-lg border-b border-gray-200">
                  {planFeatures[plan.id as keyof typeof planFeatures].map((feature) => (
                    <li key={feature} className="flex gap-2 mb-2">
                      <span className="font-medium text-xl">•</span>
                      <span className="font-semibold">{feature}</span>
                    </li>
                  ))}
                </ul>
                <div className="p-4 [&>button]:w-full">
                  <button className="button-primary" onClick={() => submit(plan.id, period)} disabled={submitting}>
                    Select
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlansSelectPage
