import { FC, PropsWithChildren } from "react"
import TableContext, { TableContextType } from "./tableContext"

const TableProvider: FC<PropsWithChildren<TableContextType>> = ({ children, ...value }) => {
  return (
    <TableContext.Provider value={value}>
      {children}
    </TableContext.Provider>
  )
}

export default TableProvider