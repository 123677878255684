import { signInWithCustomToken, signInWithEmailAndPassword } from 'firebase/auth'
import { FormEvent, useEffect, useState } from 'react'
import firebase from '../../../config/firebase'
import { FirebaseError } from 'firebase/app'
import { Link, useSearchParams } from 'react-router-dom'
import { logEvent } from 'firebase/analytics'

const AuthPage = () => {
  const [sp] = useSearchParams()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const emailValid = email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!emailValid) return setError('Please enter a valid email address')
    if (!password) return setError('Please enter a password')
    setError(undefined)
    setSubmitting(true)
    signInWithEmailAndPassword(firebase.auth, email, password)
      .then(() => {
        logEvent(firebase.analytics, 'sign_in', { method: 'email' })
      })
      .catch((err: FirebaseError) => {
        setSubmitting(false)
        switch (err.code) {
          case 'auth/invalid-email':
            setError('Please enter a valid email address')
            break
          case 'auth/user-disabled':
            setError('This account has been disabled')
            break
          case 'auth/user-not-found':
            setError('This account does not exist')
            break
          case 'auth/wrong-password':
            setError('Incorrect password')
            break
          case 'auth/invalid-login-credentials':
            setError('Invalid login credentials')
            break
          default:
            setError('An unknown error occurred')
        }
      })

    return false
  }

  useEffect(() => {
    const token = sp.get('token')
    if (token) {
      if (submitting) return
      setSubmitting(true)
      signInWithCustomToken(firebase.auth, token).catch((err: FirebaseError) => {
        setError(err.message)
        setSubmitting(false)
      })
    }
  }, [sp, submitting])

  return (
    <div className="flex h-full w-full p-8 items-center justify-center">
      <div className="flex flex-col p-8 gap-8 rounded-lg bg-white w-[32rem]">
        <h1 className="text-brand-primary">Login</h1>
        <form className="flex flex-col w-full gap-8" name="login" onSubmit={handleSubmit}>
          <input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            className="grow p-5 default rounded-md bg-base-50 border border-base-100 text-base-900 text-base"
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="grow p-5 rounded-md default bg-base-50 border border-base-100 text-base-900 text-base"
          />
          <button className="mt-4 button-blue" disabled={submitting} children={submitting ? '...' : 'Log in'} onSubmit={handleSubmit} />
        </form>
        {error && <p className="text-rose-500 text-center">{error}</p>}
        <div>
          <p className="text-base-900 text-lg text-center pb-2">
            Dont have an account?{' '}
            <Link className="text-brand-primary hover:underline" to="/auth/register">
              Sign up
            </Link>
          </p>
          <p className="text-base-900 text-center pt-2">
            Forgot password?{' '}
            <Link className="text-brand-primary hover:underline" to="/auth/reset-password">
              Reset
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default AuthPage
