import { ColumnDef } from '@tanstack/react-table'
import { IPurchaseOrderColumnItem } from 'utils/purchaseOrders'
import { default as workingColumnDef} from '../Working/tableDef'

const filteredOut = [
  'supplier',
  'buyer',
  'estDailySalesVelocity',
  'daysUntilReorder',
  'qtyToReorderOnRedYellow',
  'supplyChainQty',
]

const columnDef: ColumnDef<IPurchaseOrderColumnItem>[] = workingColumnDef
  .filter((column) => !filteredOut.includes(column.id as string)) as ColumnDef<IPurchaseOrderColumnItem>[]

export default columnDef
