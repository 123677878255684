import { useQuery } from '@tanstack/react-query'
import housedAsinsApi from 'api/housedAsins'
import { FC } from 'react'
import { HousedAsin } from 'types/housedAsins'
import TableSpanner from 'components/TableSpanner'
import { Row } from '@tanstack/react-table'
import Item from './Item'
import Loader from 'components/loaders/Loader'

interface props {
  row: Row<HousedAsin>
  housed: string
  asin: string
  open: boolean
  onDone: (asin?: HousedAsin) => void
}

const SimilarItems: FC<props> = ({ housed, row, asin, open, onDone }) => {
  const { data: items } = useQuery({
    queryKey: ['housed', 'housed', 'similar', asin, housed],
    queryFn: async () => housedAsinsApi.getHousedSimilar(housed).then((res) => res.items),
    enabled: open,
  })

  if (!open) return null

  return (
    <>
      {!items?.length ? (
        !items ? (
          <TableSpanner rows={3}>
            <Loader />
          </TableSpanner>
        ) : (
          <TableSpanner rows={2}>No other procurement options found</TableSpanner>
        )
      ) : (
        items.map((housed) => <Item key={row.id} row={{ ...row, original: housed }} onDone={onDone} nested />)
      )}
    </>
  )
}

export default SimilarItems
