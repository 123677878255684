import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import Tooltip from 'components/Tooltip'
import useTableContext from 'contexts/Table/useTableContext'
import { FC, useCallback, useMemo } from 'react'
import { usNumber } from 'utils/formatting'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'
import { RestockingItem } from 'types/restocking'
import Checkbox from 'components/Checkbox'
import KeepaHistoryButton from 'components/KeepaHistoryButton'
import useKeepaStatsRenderer from 'components/keepaStats/renderer'
import UPCItemDBButton from 'components/UPCItemDB'

interface props {
  row: Row<RestockingItem>
}

const Item: FC<props> = ({ row }) => {
  const item = row.original
  const { extra, select } = useTableContext()
  const keepaRenderer = useKeepaStatsRenderer()

  const supplierNames = extra?.supplierNames || []
  const updateVelocities = extra?.updateVelocities
  const updateReorderSettings = extra?.updateReorderSettings
  const showStockHistory = extra?.showStockHistory

  const formattedInventoryBreakdown = Object.entries(item.amazonInventoryBreakdown)
    .map(([type, qty]) => {
      const formattedType = type
        .replace('Quantity', '')
        .replace(/([A-Z])/g, ' - $1')
        .replace(/^./, (str) => str.toUpperCase())
      return `${formattedType}: ${qty}`
    })
    .join('\n')

  const formattedInvAgeBreakdown = [
    'Inventory Age',
    `0-60 Days: ${item.fbaInventoryAgeBreakdown.invAge0To60Days || 0}`,
    `61-90 Days: ${item.fbaInventoryAgeBreakdown.invAge61To90Days || 0}`,
    `91-180 Days: ${item.fbaInventoryAgeBreakdown.invAge91To180Days || 0}`,
    `181-330 Days: ${item.fbaInventoryAgeBreakdown.invAge181To330Days || 0}`,
    `331-365 Days: ${item.fbaInventoryAgeBreakdown.invAge331To365Days || 0}`,
    `365+ Days: ${item.fbaInventoryAgeBreakdown.invAge365PlusDays || 0}`,
  ].join('\n')

  const workingPOSuppliers = Array.from(new Set(item.workingPos?.map((po) => po.supplier)))
  const openPOSuppliers = Array.from(new Set(item.openPOs?.map((po) => po.supplier)))
  const workingOrderSuppliers = Array.from(new Set([...workingPOSuppliers, ...openPOSuppliers]))
  const inWarehouseSuppliers = Array.from(new Set(item.inWarehouse?.map((po) => po.supplier)))
  const inboundOrdersSuppliers = Array.from(new Set(item.inboundPOs?.map((po) => po.supplier)))

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const futureInStockDate = useCallback((leadTime: number) => {
    const date = new Date()
    date.setDate(date.getDate() + leadTime)
    return date.toLocaleDateString()
  }, [])

  const inStockLeadTime = useMemo(() => {
    const leadTime = Math.min(...[...(item.workingPos || []), ...(item.openPOs || []), ...(item.inboundPOs || []), ...(item.inWarehouse || [])].map((po) => +po.lead_time))
    if (item.amazonInventory) return 'In Stock'
    if (leadTime === Infinity) return 'No Orders'
    return futureInStockDate(leadTime)
  }, [item.workingPos, item.openPOs, item.inboundPOs, item.inWarehouse, item.amazonInventory, futureInStockDate])

  const overUnderBuyingClass = useCallback((qty: number) => {
    if (qty > 0) return 'bg-red-200'
    if (qty < 0) return 'bg-yellow-200'
    return ''
  }, [])

  const stockStatusClass = useMemo(() => {
    if (item.stockStatus === 'Out of stock') return `bg-[#F8717140] text-[#F87171]`
    if (item.stockStatus === 'Time to reorder') return `bg-[#F0A30D40] text-[#F0A30D]`
    if (item.stockStatus === 'Overstocked') return `bg-[#52CBFF40] text-[#52CBFF]`
    return `bg-[#12B76A40] text-[#12B76A]`
  }, [item.stockStatus])

  const cellRenderers: RowRenderer<RestockingItem> = useMemo(
    () => ({
      selection: (cell) =>
        !!select && (
          <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell}>
            <div className="flex items-center gap-2">
              {!!select && (
                <button onClick={() => select?.onSelectClick(item.id)}>
                  <Checkbox checked={allSelected ? !isChecked : isChecked} />
                </button>
              )}
              <Tooltip
                interactive
                className="!z-[1000] !max-w-none rounded-lg !border-2 !bg-surface-primary border-text-primary p-4 max-h-[50%] overflow-y-auto"
                text={
                  <div className="flex flex-col">
                    <span className="text-sm font-medium text-text-primary">Totals</span>
                    <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Supplier</th>
                            <th>Qty</th>
                            <th>Submitted Date</th>
                            <th>Cogs</th>
                            <th>Over/Under Buying</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.suppliers?.join(', ')}</td>
                            <td>{usNumber(+item.totalQty, 0)}</td>
                            <td>$ {usNumber(+item.totalValue || 0, 2)}</td>
                            <td className={overUnderBuyingClass(+item.overUnderBuyingValue)}>$ {usNumber(+(item.overUnderBuyingValue || 0))}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <span className="text-sm font-medium text-text-primary mt-4">Working Orders</span>
                    <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Supplier</th>
                            <th>Qty</th>
                            <th>Submitted Date</th>
                            <th>Cogs</th>
                            <th>Over/Under Buying</th>
                            <th>Future In-Stock Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {[...(item.workingPos || []), ...(item.openPOs || [])]?.map((po) => (
                            <tr key={po.supplier}>
                              <td>{po.supplier}</td>
                              <td>{usNumber(po.qty, 0)}</td>
                              <td>{new Date(po.submittedDate).toLocaleString()}</td>
                              <td>$ {usNumber((po.qty * item.workingOrderValue) / item.workingOrderQty, 2)}</td>
                              <td className={overUnderBuyingClass(po.qty * item.overUnderBuyingValue)}>$ {usNumber((po.qty * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}</td>
                              <td>{futureInStockDate(+po.lead_time)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <span className="text-sm font-medium text-text-primary mt-4">Inbound Shipments</span>
                    <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Supplier</th>
                            <th>Qty</th>
                            <th>Submitted Date</th>
                            <th>Cogs</th>
                            <th>Over/Under Buying</th>
                            <th>Future In-Stock Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.inboundPOs?.map((po) => (
                            <tr key={po.supplier}>
                              <td>{po.supplier}</td>
                              <td>{usNumber(po.qty, 0)}</td>
                              <td>{new Date(po.submittedDate).toLocaleString()}</td>
                              <td>$ {usNumber((po.qty * item.inboundOrderValue) / item.inboundOrderQty, 2)}</td>
                              <td className={overUnderBuyingClass(po.qty * item.overUnderBuyingValue)}>$ {usNumber((po.qty * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}</td>
                              <td>{futureInStockDate(+po.lead_time)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <span className="text-sm font-medium text-text-primary mt-4">In Warehouse</span>
                    <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Supplier</th>
                            <th>Qty</th>
                            <th>Submitted Date</th>
                            <th>Cogs</th>
                            <th>Over/Under Buying</th>
                            <th>Future In-Stock Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.inWarehouse?.map((po) => (
                            <tr key={po.supplier}>
                              <td>{po.supplier}</td>
                              <td>{usNumber(po.qty, 0)}</td>
                              <td>{new Date(po.submittedDate).toLocaleString()}</td>
                              <td>$ {usNumber((po.qty * item.warehouseOrderValue) / item.warehouseOrderQty, 2)}</td>
                              <td className={overUnderBuyingClass(po.qty * item.overUnderBuyingValue)}>$ {usNumber((po.qty * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}</td>
                              <td>{futureInStockDate(+po.lead_time)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <span className="text-sm font-medium text-text-primary mt-4">At Amazon</span>
                    <div className="border border-border-primary mt-2 rounded-lg overflow-hidden">
                      <table className="w-full">
                        <thead>
                          <tr>
                            <th>Suppliers</th>
                            <th>Qty</th>
                            <th>Cogs</th>
                            <th>Over/Under Buying</th>
                            <th>Future In-Stock Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{item.suppliers?.join(', ')}</td>
                            <td>{usNumber(item.amazonInventory, 0)}</td>
                            <td>$ {usNumber(item.amazonInventoryValue || 0, 2)}</td>
                            <td className={overUnderBuyingClass(item.amazonInventory * item.overUnderBuyingValue)}>
                              $ {usNumber(((item.amazonInventory || 0) * (item.overUnderBuyingValue || 0)) / (+item.totalQty || 1))}
                            </td>
                            <td>{inStockLeadTime}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                }
                position="bottom-start"
              >
                <button className="p-1 hover:bg-slate-100 rounded transition-colors">
                  <Icon name="Storefront" className="w-5 h-5" />
                </button>
              </Tooltip>
            </div>
          </TD>
        ),
      image: (cell) => (
        <TD key={cell.id} cell={cell}>
          <AmazonProductImage asin={item.asin} size={48} className="!w-12 !h-8 object-cover" />
        </TD>
      ),
      asin: (cell) => (
        <TD key={cell.id} cell={cell}>
          <div className="flex items-center gap-2">
            <input type="text" className="w-[7rem]" value={item.asin} readOnly />
            {!!item.asin && <KeepaHistoryButton asin={item.asin} />}
            {!!item.upcs?.length &&
              (item.upcs.length === 1 ? (
                <UPCItemDBButton upc={item.upcs[0]} />
              ) : (
                <Tooltip
                  interactive
                  text={
                    <div className="flex flex-col gap-2">
                      {item.upcs.map((upc) => (
                        <div key={upc} className="flex items-center gap-2 py-0.5 px-1 rounded border border-border-primary">
                          <input type="text" value={upc} readOnly className="text-text-primary px-2 py-1" />
                          <UPCItemDBButton upc={upc} />
                        </div>
                      ))}
                    </div>
                  }
                  position="bottom-start"
                  className="bg-surface-primary border border-border-primary rounded-lg p-4 drop-shadow-lg"
                >
                  <button className="p-1 hover:bg-slate-100 rounded transition-colors">
                    <Icon name="Barcode" className="w-5 h-5 !text-text-primary" />
                  </button>
                </Tooltip>
              ))}
          </div>
        </TD>
      ),
      url: (cell) => (
        <TD key={cell.id} cell={cell}>
          <a href={`https://www.amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
            <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
          </a>
        </TD>
      ),
      title: (cell) => (
        <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()} className="w-32">
          <input type="text" value={item.title} readOnly />
        </TD>
      ),
      sku: (cell) => (
        <TD key={cell.id} cell={cell}>
          {item.sku}
        </TD>
      ),
      suppliers: (cell) => (
        <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
          <Tooltip text={item.suppliers?.join(', ') || ''} position="left" className="z-[1000]">
            <div className="flex gap-1 items-center w-max">
              {!!item.suppliers?.length && <BadgeSelect selected={item.suppliers[0] || ''} badges={supplierNames || []} onSelect={() => {}} editable={false} />}
              {item.suppliers && item.suppliers?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.suppliers.length - 1}</span>}
            </div>
          </Tooltip>
        </TD>
      ),
      upcs: (cell) => (
        <TD key={cell.id} cell={cell}>
          <Tooltip text={item.upcs?.join('\n') || ''} position="left" className="z-[1000]">
            <div className="flex gap-1 items-center w-max">
              {!!item.upcs?.length && <input type="text" readOnly value={item.upcs[0]} className="p-2" />}
              {item.upcs && item.upcs?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.upcs.length - 1}</span>}
            </div>
          </Tooltip>
        </TD>
      ),
      rollingAvgCogs: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.rollingAvgCogs)}
        </TD>
      ),
      estDailySalesVelocity: (cell) => (
        <TD key={cell.id} cell={cell} className="text-brand-primary hover:underline hover:text-brand-hover cursor-pointer" onClick={() => updateVelocities(item.sku)}>
          {usNumber(item.estDailySalesVelocity)}
        </TD>
      ),
      profitPerSale: (cell) => (
        <TD key={cell.id} cell={cell} className={item.profitPerSale < 0 ? 'text-red-500' : item.profitPerSale > 0 ? 'text-green-500' : ''}>
          ${usNumber(item.profitPerSale)}
        </TD>
      ),
      forecastedProfit: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.forecastedProfit)}
        </TD>
      ),
      forecastedReorderProfit: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.forecastedReorderProfit)}
        </TD>
      ),
      margin: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.margin)}%
        </TD>
      ),
      roi: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.roi)}%
        </TD>
      ),
      daysUntilReorder: (cell) => (
        <TD key={cell.id} cell={cell} className="cursor-pointer" onClick={() => updateReorderSettings(item)}>
          <span
            className={
              [
                'cursor-pointer px-2 py-1 rounded text-black hover:underline',
                Math.floor(+item.daysUntilReorder) <= 10 ? 'bg-red-100' : Math.floor(+item.daysUntilReorder) <= 15 ? 'bg-yellow-100' : 'bg-green-100',
              ].asClass
            }
          >
            {Math.floor(+item.daysUntilReorder) <= 10 ? (
              <Icon name="Warning" className="w-4 h-4 inline-block mr-1" />
            ) : Math.floor(+item.daysUntilReorder) <= 15 ? (
              <Icon name="Schedule" className="w-4 h-4 inline-block mr-1" />
            ) : (
              <Icon name="TaskDone" className="w-4 h-4 inline-block mr-1" />
            )}
            {usNumber(Math.floor(+item.daysUntilReorder), 0)}
          </span>
        </TD>
      ),
      daysOfInventoryInAmazon: (cell) => (
        <TD key={cell.id} cell={cell} className="text-brand-primary hover:underline hover:text-brand-hover cursor-pointer" onClick={() => showStockHistory(item.sku)}>
          {usNumber(Math.ceil(item.daysOfInventoryInAmazon), 0)}
        </TD>
      ),
      qtyToReorderOnRedYellow: (cell) => (
        <TD key={cell.id} cell={cell}>
          {item.qtyToReorderOnRedYellow !== null ? usNumber(Math.ceil(+item.qtyToReorderOnRedYellow), 0) : null}
        </TD>
      ),
      stockStatus: (cell) => (
        <TD key={cell.id} cell={cell}>
          <span className={['text-center rounded py-1 px-2 text-sm', stockStatusClass].join(' ')}>{item.stockStatus}</span>
        </TD>
      ),
      fbaInventory: (cell) => (
        <TD key={cell.id} cell={cell}>
          <Tooltip className="leading-[200%] z-[1000]" text={formattedInventoryBreakdown} position="left-start">
            {usNumber(item.fbaInventory, 0)}
          </Tooltip>
        </TD>
      ),
      ytdInStockRate: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.ytdInStockRate)}%
        </TD>
      ),
      missedDailyProfit: (cell) => (
        <TD key={cell.id} cell={cell}>
          $ {usNumber(item.missedDailyProfit)}
        </TD>
      ),
      missedMonthlyProfit: (cell) => (
        <TD key={cell.id} cell={cell}>
          $ {usNumber(item.missedMonthlyProfit)}
        </TD>
      ),
      missedYearlyProfit: (cell) => (
        <TD key={cell.id} cell={cell}>
          $ {usNumber(item.missedYearlyProfit)}
        </TD>
      ),
      prepCosts: (cell) => (
        <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
          <Tooltip text={item.prepCosts?.join(', ') || ''} position="left" className="z-[1000]">
            <div className="flex gap-1 items-center w-max">
              {!!item.prepCosts?.length && <BadgeSelect selected={item.prepCosts[0] || ''} badges={extra?.prepCosts || []} onSelect={() => {}} editable={false} />}
              {item.prepCosts && item.prepCosts?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{item.prepCosts.length - 1}</span>}
            </div>
          </Tooltip>
        </TD>
      ),
      historicalDaysOfSupply: (cell) => (
        <TD key={cell.id} cell={cell}>
          <Tooltip className="leading-[200%]" text={formattedInvAgeBreakdown} position="left-start">
            {usNumber(item.historicalDaysOfSupply, 0)}
          </Tooltip>
        </TD>
      ),
      fbaMinimumInventoryLevel: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.fbaMinimumInventoryLevel, 0)}
        </TD>
      ),
      lowestUnitLevelToReorder: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.lowestUnitLevelToReorder, 0)}
        </TD>
      ),
      targetUnitsStock: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.targetStock, 0)}
        </TD>
      ),
      estRecommendedReorderCogs: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.estRecommendedReorderCogs)}
        </TD>
      ),
      overUnderBuyingValue: (cell) => (
        <TD key={cell.id} cell={cell} className={overUnderBuyingClass(+item.overUnderBuyingValue)}>
          ${usNumber(+item.overUnderBuyingValue)}
        </TD>
      ),
      weightValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.weightValue)} lbs
        </TD>
      ),
      workingOrderValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.workingOrderValue)}
        </TD>
      ),
      inboundOrderValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.inboundOrderValue)}
        </TD>
      ),
      warehouseOrderValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.warehouseOrderValue)}
        </TD>
      ),
      supplyChainValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.supplyChainValue)}
        </TD>
      ),
      amazonInventoryValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.amazonInventoryValue)}
        </TD>
      ),
      totalValue: (cell) => (
        <TD key={cell.id} cell={cell}>
          ${usNumber(item.totalValue)}
        </TD>
      ),
      workingOrderQty: (cell) => (
        <TD key={cell.id} cell={cell}>
          <div className="flex items-center gap-2">
            {usNumber(item.workingOrderQty, 0)}
            <Tooltip text={workingOrderSuppliers?.join(', ') || ''} position="left" className="z-[1000]">
              <div className="flex gap-1 items-center w-max">
                {!!workingOrderSuppliers?.length && <BadgeSelect selected={workingOrderSuppliers[0] || ''} badges={supplierNames || []} onSelect={() => {}} editable={false} />}
                {workingOrderSuppliers && workingOrderSuppliers?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{workingOrderSuppliers.length - 1}</span>}
              </div>
            </Tooltip>
          </div>
        </TD>
      ),
      inboundOrderQty: (cell) => (
        <TD key={cell.id} cell={cell}>
          <div className="flex items-center gap-2">
            {usNumber(item.inboundOrderQty, 0)}
            <Tooltip text={inboundOrdersSuppliers?.join(', ') || ''} position="left" className="z-[1000]">
              <div className="flex gap-1 items-center w-max">
                {!!inboundOrdersSuppliers?.length && <BadgeSelect selected={inboundOrdersSuppliers[0] || ''} badges={supplierNames || []} onSelect={() => {}} editable={false} />}
                {inboundOrdersSuppliers && inboundOrdersSuppliers?.length > 1 && (
                  <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{inboundOrdersSuppliers.length - 1}</span>
                )}
              </div>
            </Tooltip>
          </div>
        </TD>
      ),
      warehouseOrderQty: (cell) => (
        <TD key={cell.id} cell={cell}>
          <div className="flex items-center gap-2">
            {usNumber(item.warehouseOrderQty, 0)}
            <Tooltip text={inWarehouseSuppliers?.join(', ') || ''} position="left" className="z-[1000]">
              <div className="flex gap-1 items-center w-max">
                {!!inWarehouseSuppliers?.length && <BadgeSelect selected={inWarehouseSuppliers[0] || ''} badges={supplierNames || []} onSelect={() => {}} editable={false} />}
                {inWarehouseSuppliers && inWarehouseSuppliers?.length > 1 && <span className="text-xs text-blue-500 bg-blue-50 rounded-full py-0.5 px-1">+{inWarehouseSuppliers.length - 1}</span>}
              </div>
            </Tooltip>
          </div>
        </TD>
      ),
      supplyChainQty: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.supplyChainQty, 0)}
        </TD>
      ),
      amazonInventory: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.amazonInventory, 0)}
        </TD>
      ),
      totalQty: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.totalQty, 0)}
        </TD>
      ),
      ...keepaRenderer,
    }),
    [
      keepaRenderer,
      select,
      allSelected,
      isChecked,
      item,
      overUnderBuyingClass,
      inStockLeadTime,
      futureInStockDate,
      supplierNames,
      updateVelocities,
      updateReorderSettings,
      showStockHistory,
      stockStatusClass,
      formattedInventoryBreakdown,
      extra?.prepCosts,
      formattedInvAgeBreakdown,
      workingOrderSuppliers,
      inboundOrdersSuppliers,
      inWarehouseSuppliers,
    ]
  )

  return <TR>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default Item
