import { FC, useCallback, useState } from 'react'
import Item from './components/Item'
import ContentLayout from 'components/layout/Content'
import columnDef from './tableDef'
import Table from 'components/tables/Table'
import withFiltering from 'contexts/Filter/wrapper'
import usePrepCosts from 'hooks/usePrepCosts'
import useSupplierNames from 'hooks/useSupplierNames'
import { Row } from '@tanstack/react-table'
import useRestocking from 'hooks/useRestocking'
import { RestockingItem } from 'types/restocking'
import Loader from 'components/loaders/Loader'
import useAwaitableModal from 'hooks/useAwaitableModal'
import VelocityWeightsModal from './components/VelocityWeights'
import ReorderSettingsModal from './components/ReorderSettings'
import restockingApi from 'api/restocking'
import useSelect from 'hooks/useSelect'
import Icon from 'assets/icons/iconset'
import UpdateColumns from 'components/modals/UpdateColums'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Dashboard from './components/Dashboard'
import StockHistoryModal from './components/StockHistory'
import CreateWorkingPO from '../housedAsins/Housed/components/CreateWorkingPO'
import Tooltip from 'components/Tooltip'
import AddAsin from './components/AddAsin'
import { HousedAsin } from 'types/housedAsins'
import UpdatePreferencesModal from './components/UpdatePreferencesModal'
import FilterPresets from 'components/modals/FilterPresets'

const RestockingPage: FC = () => {
  const { opFilters: filters, search, ordering } = useFilteringContext()
  const { syncState, items, loadNextPage, loadingNextPage } = useRestocking()
  const { prepCosts } = usePrepCosts()
  const [updateVelocities, VelocityWeightsComponent] = useAwaitableModal(VelocityWeightsModal, undefined)
  const [showStockHistory, StockHistoryComponent] = useAwaitableModal(StockHistoryModal, undefined)
  const [updateReorderSettings, ReorderSettingsComponent] = useAwaitableModal(ReorderSettingsModal, undefined)
  const [createWorkingPO, CreateWorkingPOModal] = useAwaitableModal(CreateWorkingPO, [])
  const [changePreferences, UpdatePreferences] = useAwaitableModal(UpdatePreferencesModal, undefined)
  const select = useSelect()

  const [exporting, setExporting] = useState(false)
  const [loadingSelection, setLoadingSelection] = useState(false)

  const [asinsToRestock, setAsinsToRestock] = useState<HousedAsin[]>([])
  const [addAsinOpen, setAddAsinOpen] = useState(false)

  const exportData = useCallback(() => {
    if (exporting) return
    setExporting(true)
    restockingApi
      .exportRestocking(
        columnDef.map((col) => [col.id as string, col.header as string]),
        { search, ordering, filters }
      )
      .finally(() => setExporting(false))
  }, [exporting, search, ordering, filters])

  const { supplierNames } = useSupplierNames()

  const renderRow = useCallback((row: Row<RestockingItem>) => <Item key={row.id} row={row} />, [])

  const onCreateWorkingPO = useCallback(async () => {
    if (loadingSelection) return
    setLoadingSelection(true)
    const selection = select.selected
    const allSelected = select.allSelected
    const ids = allSelected ? undefined : selection
    const except = allSelected ? selection : undefined
    const housedAsins = await restockingApi.getRestockingSelectionHoused({
      ids, except, search, ordering, filters,
    }).finally(() => setLoadingSelection(false))
    if (housedAsins.items.length > 1000) {
      const proceed = window.confirm(`You are about to create a Working PO with ${housedAsins.items.length} items. This may cause performance issues. Do you want to proceed?`)
      if (!proceed) return
    }
    select.clear();
    setAsinsToRestock(housedAsins.items)
    setAddAsinOpen(true)
  }, [filters, ordering, search, select, loadingSelection])

  const canDoBulk = select.selected.length > 0 || select.allSelected
  const bulkInProgress = exporting || loadingSelection

  return (
    <div className="w-full h-full flex flex-col overflow-hidden bg-surface-light relative">
      {!syncState.synced && false && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[1000] flex items-center justify-center">
          <div className="bg-white rounded-lg flex flex-col items-center justify-center p-4">
            <Loader />
            <div className="text-center mt-3 font-medium">Syncing Sales Data... {syncState.syncedUntil ? `Synced until: ${new Date(syncState.syncedUntil).toDateString()}` : ''}</div>
            <div className="text-center mt-1 text-slate-500">This is a long running operation, don't close this page</div>
          </div>
        </div>
      )}
      <AddAsin open={addAsinOpen} close={() => setAddAsinOpen(false)} items={asinsToRestock} createWorkingPO={createWorkingPO} />
      <VelocityWeightsComponent />
      <ReorderSettingsComponent />
      <StockHistoryComponent />
      <CreateWorkingPOModal />
      <UpdatePreferences />
      <Dashboard />
      <ContentLayout
        wrapperClass="relative"
        underSearch={
          canDoBulk ? (
            <div className="flex-col flex gap-2">
              <div className="flex gap-4">
                <button className="button-primary w-max" onClick={onCreateWorkingPO} disabled={bulkInProgress}>
                  Create a Working PO
                </button>
              </div>
            </div>
          ) : undefined
        }
        buttons={
          <div className="flex items-center justify-end gap-4 flex-wrap">
            {syncState !== undefined && (
              <div className="flex items-center gap-3">
                <span className="text-sm font-semibold text-base-500">
                  Catalogue synced At: {syncState.syncedUntil ? new Date(syncState.syncedUntil).toDateString() : !syncState.syncedUntil ? 'Never' : '...'}
                </span>
              </div>
            )}
            <button className="button-secondary" onClick={changePreferences}>
              Preferences
            </button>
            <UpdateColumns table="restocking" columnDef={columnDef} />
            <FilterPresets columnDef={columnDef} />
            <Tooltip text="Download File" position="left">
              <button className="button-primary !p-2.5" disabled={exporting} onClick={exportData}>
                <Icon name="DownloadSimple" className="w-5 h-5" />
              </button>
            </Tooltip>
          </div>
        }
      >
        <Table
          name="restocking"
          columns={columnDef}
          initialPinState={{
            left: ['image', 'asin', 'url', 'title'],
          }}
          items={items}
          locked={false}
          renderRow={renderRow}
          loading={!items || syncState === undefined}
          loadingNext={loadingNextPage}
          onBottom={loadNextPage}
          select={select}
          extra={{ supplierNames, prepCosts: prepCosts?.map((p) => p.name), updateVelocities, updateReorderSettings, showStockHistory }}
        />
      </ContentLayout>
    </div>
  )
}

export default withFiltering(RestockingPage, 'restocking-page')
