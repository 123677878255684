import { FC } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Widget } from '@typeform/embed-react'
import firebase from "config/firebase";

const FormPage: FC = () => {
    const [sp] = useSearchParams();
    const {id} = useParams() as {id: string};
    const navigate = useNavigate();

    const onSubmit = () => {
        navigate(sp.get("redirect") || "/app");
    };

    return (
        <Widget id={id} className="w-full h-full" onSubmit={onSubmit} shareGaInstance={firebase.app.options.measurementId} />
    )
}

export default FormPage;