import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query'
import purchaseOrdersApi from 'api/purchaseOrders'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { useCallback, useMemo } from 'react'
import { WorkingPurchaseOrderItem } from 'types/purchaseOrders'
import { IApiFilter } from 'types/tableFiltering'

const queryFn = async ({ pageParam: page, queryKey }: { pageParam: number; queryKey: [string, IApiFilter] }) => {
  return purchaseOrdersApi.getWorkingPurchaseOrders({ page, ...queryKey[1] })
}

const overviewQueryFn = async ({ queryKey }: { queryKey: [string, IApiFilter] }) => {
  return purchaseOrdersApi.getWorkingPurchaseOrdersOverview(queryKey[1])
}

const useWorkingPOs = () => {
  const filter = useFilteringContext()
  const { search, ordering, opFilters: filters } = filter

  const queryKey = useMemo(
    () =>
      [
        'working-purchase-orders',
        {
          search,
          ordering,
          filters,
        },
      ] as [string, IApiFilter],
    [search, ordering, filters]
  )

  const {
    data,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage: hasMore,
  } = useInfiniteQuery({
    queryKey,
    queryFn,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next || undefined,
  })

  const loadingNextPage = isFetchingNextPage

  const items = useMemo(() => data ? [...data.pages.flatMap((p) => p.items)] : undefined, [data])

  const {
    data: overview,
    isRefetching,
    refetch: refetchOverview,
  } = useQuery({
    queryKey: [
      'working-purchase-orders-overview',
      queryKey[1],
    ],
    queryFn: overviewQueryFn,
  })

  const qc = useQueryClient()

  const setItems = useCallback(
    (action: (old: WorkingPurchaseOrderItem[] | undefined) => WorkingPurchaseOrderItem[] | undefined) => {
      qc.setQueriesData(
        {
          predicate(query) {
            return query.queryKey[0] === 'working-purchase-orders'
          },
        },
        (old: typeof data) => {
          if (!old) return old
          return {
            ...old,
            pages: old.pages.map((p) => ({
              ...p,
              items: action(p.items),
            })),
          }
        }
      )
      refetchOverview()
    },
    [qc, refetchOverview]
  )

  return {
    overview: isRefetching ? undefined : overview,
    load: refetch,
    loadNextPage: isFetchingNextPage || !hasMore ? undefined : fetchNextPage,
    hasMore,
    setItems,
    loadingNextPage,
    items,
  }
}

export default useWorkingPOs
