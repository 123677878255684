import { createRef, Dispatch, SetStateAction } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { IUser } from 'types/user'
import FullpageLoader from './components/loaders/FullpageLoader'
import useAuthContext from './contexts/Auth/useAuthContext'
import UserProvider from './contexts/User/UserProvider'
import AuthorizedRouter from './routes/AuthorizedRouter'
import UnauthorizedRouter from './routes/UnauthorizedRouter'
import useNoScrollNumberInput from 'hooks/useNoScrollNumberInput'
import SubscriptionRequiredBlocker from 'components/SubscriptionRequiredBlocker'
import { isDemo } from 'constants/demo'
import NonDemoBlocker from 'components/NonDemoBlocker'

export const userData = createRef<{ setUser: Dispatch<SetStateAction<IUser | null>> }>()

function Root() {
  const user = useAuthContext()
  useNoScrollNumberInput();

  if (user === undefined && !isDemo) return <FullpageLoader />

  return (
    <UserProvider ref={userData}>
      <SubscriptionRequiredBlocker />
      <NonDemoBlocker />
      <Routes>
        <Route path="app/*" element={user !== null || isDemo ? <AuthorizedRouter /> : <Navigate to="/auth" replace />} />
        <Route path="auth/*" element={user === null && !isDemo ? <UnauthorizedRouter /> : <Navigate to="/app" replace />} />
        <Route path="*" element={<Navigate to="/app" replace />} />
      </Routes>
    </UserProvider>
  )
}

export default Root
