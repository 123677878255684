import { FC, FormEvent, useEffect, useState } from 'react'
import suppliersApi from 'api/suppliers'
import { handleError } from 'helpers/errors'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import { SupplierLead } from 'types/supplierLeads'
import AreYouSure from 'components/modals/AreYouSure'
import useAwaitableModal from 'hooks/useAwaitableModal'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import { stateArea, yesNo, contactMethods, statuses, amazonIndustries } from 'constants/badgeSelect'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'

interface props {
  onUpdate: (lead?: SupplierLead) => void
  row: Row<SupplierLead>
  onRemove: () => void
}

const LeadRow: FC<props> = ({ onUpdate, row, onRemove }) => {
  const lead = row.original
  const [submitting, setSubmitting] = useState(false)
  const { select, locked } = useTableContext()
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})
  const [values, setValues] = useState({
    ...lead,
  } as SupplierLead)
  const [editingWebsite, setEditingWebsite] = useState(false)

  const onSubmit = (e?: FormEvent) => {
    e?.preventDefault()
    if (locked) return false
    if (submitting) return
    setSubmitting(true)
    setValues((old) => {
      if (!old.name) {
        handleError('Name is required')
        return old
      }
      suppliersApi
        .updateLead(lead.id, old)
        .then(() => {
          onUpdate({ ...old })
          setEditingWebsite(false)
        })
        .finally(() => setSubmitting(false))
      return old
    })
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return () => {
      setValues((old) => ({ ...old, [key]: value }))
    }
  }

  const [favicon, setFavicon] = useState<string | null>(null)
  useEffect(() => {
    if (!values.website) return
    try {
      const url = new URL(values.website)
      const favicon = `https://www.google.com/s2/favicons?domain=${url.href}&sz=${32}`
      setFavicon(favicon)
    } catch {
      return
    }
  }, [values.website])

  const onRemoveClick = () => {
    if (submitting) return
    setSubmitting(true)
    areYouSure({
      header: 'Delete Supplier Lead',
      description: 'Are you sure you want to delete Supplier Lead from your account? You can’t restore the removed ones.',
      action: 'Delete',
    })
      .then(() => {
        suppliersApi
          .deleteLead(lead.id)
          .then(() => {
            onRemove()
          })
          .finally(() => setSubmitting(false))
      })
      .finally(() => setSubmitting(false))
  }

  const isChecked = !!select?.selected.includes(lead.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<SupplierLead> = {
    selection: (cell) =>
      !!select && (
        <TD key={cell.id} onClick={() => select?.onSelectClick(lead.id)} cell={cell}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      ),
    name: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex gap-1 items-center">
          <input className="table-input" type="text" readOnly={locked} value={values.name} onBlur={onSubmit} onChange={(e) => onValueChange('name', e.target.value)()} />
          <button className="rounded-lg hover:bg-surface-secondary group transition-colors hover:text-text-primary" style={{ pointerEvents: locked ? 'none' : 'auto' }} onClick={onRemoveClick}>
            <Icon name="TrashSimple" className="group-hover:opacity-100 opacity-0 transition-all w-5 h-5" />
          </button>
        </div>
      </TD>
    ),
    website: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex items-center gap-2">
          {values.website && (!editingWebsite || submitting || locked) ? (
            <a href={values.website} target="_blank" rel="noreferrer">
              {favicon && <img src={favicon} alt="favicon" className="w-6 h-6 rounded-lg" />}
              {!favicon && <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />}
            </a>
          ) : (
            <input className="table-input" type="text" readOnly={locked} value={values.website} onBlur={onSubmit} onChange={(e) => onValueChange('website', e.target.value)()} />
          )}
          {values.website && !editingWebsite && !locked && (
            <button className="p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary" onClick={() => setEditingWebsite(true)}>
              <Icon name="CaretDoubleLeft" className="group-hover:opacity-100 opacity-0 transition-all w-4 h-4" />
            </button>
          )}
        </div>
      </TD>
    ),
    email: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="text" className="table-input" value={values.email} readOnly={locked} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('email', e.target.value)()} />
      </TD>
    ),
    phone: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="text" className="table-input" value={values.phone} readOnly={locked} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('phone', e.target.value)()} />
      </TD>
    ),
    industry: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          badges={amazonIndustries}
          selected={values.industry}
          onSelect={(id) => {
            onValueChange('industry', id)()
            onSubmit()
          }}
          editable={!locked}
        />
      </TD>
    ),
    state: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          badges={stateArea}
          selected={values.state}
          onSelect={(id) => {
            onValueChange('state', id)()
            onSubmit()
          }}
          editable={!locked}
        />
      </TD>
    ),
    notes: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="text" className="table-input" readOnly={locked} value={values.notes} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('notes', e.target.value)()} />
      </TD>
    ),
    contactFirst: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          badges={yesNo}
          selected={values.contactFirst}
          onSelect={(id) => {
            onValueChange('contactFirst', id)()
            onSubmit()
          }}
          editable={!locked}
        />
      </TD>
    ),
    dateOfContact: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="date" className="table-input" readOnly={locked} value={values.dateOfContact || ''} onBlur={onSubmit} onChange={(e) => onValueChange('dateOfContact', e.target.value)()} />
      </TD>
    ),
    contactMethod: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          badges={contactMethods}
          selected={values.contactMethod}
          onSelect={(id) => {
            onValueChange('contactMethod', id)()
            onSubmit()
          }}
          editable={!locked}
        />
      </TD>
    ),
    conversation: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="text" className="table-input" readOnly={locked} value={values.conversation || '---'} onBlur={onSubmit} onChange={(e) => onValueChange('conversation', e.target.value)()} />
      </TD>
    ),
    outcome: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          badges={statuses}
          selected={values.outcome}
          onSelect={(id) => {
            onValueChange('outcome', id)()
            onSubmit()
          }}
          editable={!locked}
        />
      </TD>
    ),
    dateOfNextContact: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="date" className="table-input" readOnly={locked} value={values.dateOfNextContact || ''} onBlur={onSubmit} onChange={(e) => onValueChange('dateOfNextContact', e.target.value)()} />
      </TD>
    ),
  }

  return (
    <>
      <AreYouSureModal />
      <TR className={['relative', submitting && 'animate-pulse'].asClass}>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
    </>
  )
}

export default LeadRow
