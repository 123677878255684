import { FC, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import TH from './TH'
import Empty from 'components/Empty'
import TableProvider from 'contexts/Table/TableProvider'
import Checkbox from 'components/Checkbox'
import useSelect from 'hooks/useSelect'
import usePreferences from 'contexts/Preferences/useContext'
import { ColumnDef, ColumnPinningState, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table'
import { useVirtualizer, VirtualItem } from '@tanstack/react-virtual'
import TableLoadingRow from './LoadingRow'
import useSaveScroll from 'hooks/useSaveScroll'
import { RowProvider } from './RowProvider'
import useFilteringContext from 'contexts/Filter/useFilteringContext'

export interface TableProps<T extends Record<string, any> = any> {
  items?: T[]
  disabled?: boolean
  select?: ReturnType<typeof useSelect>
  noSelectAll?: boolean
  renderRow: (row: Row<T>, index: number) => ReactNode
  onBottom?: () => void
  initialPinState?: ColumnPinningState
  name: string
  columns: ColumnDef<T>[]
  saveScrollKey?: string
  locked?: boolean
  loading?: boolean
  loadingNext?: boolean
  emptyText?: string
  emptyAction?: () => void
  emptyActionText?: string
  extra?: any
}

const Table: FC<TableProps> = ({
  name,
  columns: defaultColumns,
  saveScrollKey,
  initialPinState = {},
  items,
  select,
  loading,
  loadingNext,
  renderRow,
  onBottom,
  disabled,
  noSelectAll,
  extra,
  locked = false,
  emptyText = 'No Data',
  emptyAction,
  emptyActionText,
}) => {
  const { anyFilterActive, reset: resetFilters } = useFilteringContext()
  const { preferences } = usePreferences()
  const withSelection = useMemo(() => !!select, [select])

  const baseColumns = useMemo(() => {
    const preference = preferences?.tables?.[name]
    if (!preference)
      return {
        columns: defaultColumns,
        pins: initialPinState,
      }
    const columns = preference.columns
      .map(({ id }) => {
        const def = defaultColumns.find((col) => col.id === id)
        if (!def) return null
        return def
      })
      .filter(Boolean) as ColumnDef<any>[]
    return {
      columns,
      pins: {
        left: preference.columns.filter((pref) => pref.pin === 'left').map((pref) => pref.id),
        right: preference.columns.filter((pref) => pref.pin === 'right').map((pref) => pref.id),
      },
    }
  }, [preferences, defaultColumns, initialPinState, name])

  const { columns, pinState } = useMemo(() => {
    const originalSelectionColDef = baseColumns.columns.find(({ id }) => id === 'selection')
    const columns = [...(withSelection && !originalSelectionColDef ? [{ id: 'selection' }] : []), ...baseColumns.columns]
    const pinState = {
      left: ['selection', ...(baseColumns.pins.left || [])],
      right: [...(baseColumns.pins.right || [])],
    }
    return { columns, pinState }
  }, [baseColumns, withSelection])

  const checkState = useMemo(() => (select?.allSelected ? (select.selected.length ? 'semi' : 'checked') : 'unchecked'), [select])
  const saveScrollRef = useSaveScroll(saveScrollKey)

  const loaderItems = useMemo(() => Array(40).fill(undefined), [])

  const data = useMemo(() => (items && !loading ? (loadingNext ? items.concat(loaderItems) : items.length ? items : [undefined]) : loaderItems), [items, loading, loadingNext, loaderItems])

  const scrollRef = useRef<HTMLDivElement | null>(null)

  const fetchMoreOnBottomReached =useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement
        //once the user has scrolled within 500px of the bottom of the table, fetch more data if we can
        if (
          scrollHeight - scrollTop - clientHeight < 500 &&
          !loading && !loadingNext
        ) {
          onBottom?.()
        }
      }
    },
    [loading, loadingNext, onBottom]
  )
  
  useEffect(() => {
    fetchMoreOnBottomReached(scrollRef.current)
  }, [fetchMoreOnBottomReached])

  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
    defaultColumn: {
      minSize: 1,
      size: 0,
    },
    state: {
      columnPinning: pinState,
    },
  })

  const rows = table.getRowModel().rows

  const headers = table.getFlatHeaders()

  const resize = useCallback(() => {
    if (scrollRef.current) {
      if (!headers.length) return
      const wrapper = scrollRef.current
      let total = 0;
      let initialColumnSizing = table.getFlatHeaders().map((header) => {
        let size = header.getSize()
        wrapper.querySelectorAll(`[data-col-id=${header.column.id}]`).forEach((el) => size = Math.max(el.scrollWidth, size))
        total += size;
        return [header.column.id, size] as const
      })
      const remaining = Math.max(wrapper.clientWidth - total, 0)
      const columnsNoSelection = initialColumnSizing.filter(([id]) => id !== 'selection')
      initialColumnSizing = initialColumnSizing.map(([id, size]) => (id === 'selection' ? [id, size] : [id, size + Math.floor(remaining / columnsNoSelection.length)]))
      const updated = Object.fromEntries(initialColumnSizing)
      if (headers.every((header) => updated[header.column.id] === header.getSize())) return
      table.setColumnSizing(updated)
    }
  }, [headers, table, scrollRef])

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48,
    getScrollElement: () => saveScrollRef.current,
    measureElement: typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1 ? (element) => element?.scrollHeight : undefined,
    overscan: 5,
  })

  useLayoutEffect(() => {
    if (!scrollRef.current) return
    const mo = new MutationObserver(resize)
    mo.observe(scrollRef.current, {subtree: true, childList: true})

    resize()

    return () => mo.disconnect()
  }, [resize, scrollRef])

  const renderRowPossiblyLoading = useCallback(
    (row: Row<any>, index: number) => {
      if (row.original === undefined) {
        return <TableLoadingRow key={row.id} row={row} />
      }

      return renderRow(row, index)
    },
    [renderRow]
  )

  const renderVirtualRow = useCallback(
    (virtual: VirtualItem) => {
      const row = rows[virtual.index]
      return (
        <RowProvider key={row.id} rowVirtualizer={rowVirtualizer} virtualRow={virtual}>
          {renderRowPossiblyLoading(row, virtual.index)}
        </RowProvider>
      )
    },
    [rows, rowVirtualizer, renderRowPossiblyLoading]
  )

  return (
    <TableProvider table={table} select={select} extra={extra} locked={locked}>
      <div className="w-full h-full overflow-hidden relative">
        {!!items && !items?.length && (
          <div className="flex flex-col w-full items-center justify-center absolute inset-0 z-[100] backdrop-blur-[256px]">
            <Empty text={emptyText} action={emptyAction} actionText={emptyActionText} />
            {anyFilterActive && (
              <button className="p-2 button-primary" onClick={() => resetFilters()}>
                Clear Filters
              </button>
            )}
          </div>
        )}
        {anyFilterActive && items?.length && (
          <button className="p-1 button-primary !text-xs absolute bottom-4 left-4 drop-shadow-xl z-[1000]" onClick={() => resetFilters()}>
            Reset Filters
          </button>
        )}
        <div
          ref={(e) => {
            scrollRef.current = e;
            saveScrollRef.current = e;
          }}
          onScroll={() => fetchMoreOnBottomReached(scrollRef.current)}
          className="w-full h-full relative overflow-auto !pb-10"
        >
          <table
            style={{
              display: 'grid',
            }}
            className={disabled ? 'grayscale pointer-events-none cursor-default' : undefined}
          >
            <thead
              style={{
                display: 'grid',
                position: 'sticky',
                top: 0,
                zIndex: 1,
              }}
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="flex w-full h-max">
                  {headerGroup.headers.map((header) => {
                    if (header.id === 'selection') {
                      return noSelectAll ? (
                        <TH key={header.id} columnDef={{ header: '', id: 'selection' }} header={header} />
                      ) : (
                        <TH key={header.id} columnDef={{ header: '', id: 'selection', meta: { filters: 'string' } }} header={header} onClick={select?.onSelectAllClick}>
                          <Checkbox checked={checkState !== 'unchecked'} semichecked={checkState === 'semi'} />
                          {typeof header.column.columnDef.header !== 'function' && <span className="min-w-max grow mr-4">{header.column.columnDef.header as string}</span>}
                        </TH>
                      )
                    }
                    return <TH key={header.id} columnDef={header.column.columnDef} header={header} />
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="relative grid divide-y divide-gray-200" style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
              {rowVirtualizer.getVirtualItems().map(renderVirtualRow)}
              {!!items && <tr />}
            </tbody>
          </table>
        </div>
      </div>
    </TableProvider>
  )
}

export default Table
