class SafeStorage {
    private type: "localStorage" | "sessionStorage";

    constructor(type: "localStorage" | "sessionStorage") {
        this.type = type;
    }

    getItem(key: string) {
        if (this.storageAvailable()) {
            return window[this.type]?.getItem(key);
        }
        return undefined;
    }

    setItem(key: string, value: string) {
        if (this.storageAvailable()) {
            window[this.type]?.setItem(key, value);
        }
    }

    removeItem(key: string) {
        if (this.storageAvailable()) {
            window[this.type]?.removeItem(key);
        }
    }

    clear() {
        if (this.storageAvailable()) {
            window[this.type]?.clear();
        }
    }

    storageAvailable(type: "localStorage" | "sessionStorage" = 'localStorage') {
        let storage;
        try {
            storage = window[type];
            const x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        } catch (e) {
            return (
                e instanceof DOMException &&
                // everything except Firefox
                (e.code === 22 ||
                    // Firefox
                    e.code === 1014 ||
                    // test name field too, because code might not be present
                    // everything except Firefox
                    e.name === 'QuotaExceededError' ||
                    // Firefox
                    e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
                // acknowledge QuotaExceededError only if there's something already stored
                storage &&
                storage.length !== 0
            );
        }
    }
}

export const safeSessionStorage = new SafeStorage("sessionStorage");
export const safeLocalStorage = new SafeStorage("localStorage");