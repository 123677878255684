import { FC, useEffect, useState } from 'react'
import Sidebar from '../../components/layout/Sidebar'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from 'components/layout/Header'
import SearchProvider from 'contexts/Search/SearchProvider'
import PnlSyncProvider from 'contexts/PnlSync'
import OutboundSyncProvider from 'contexts/OutboundSync'
import Modal from 'components/Modal'
import { safeLocalStorage } from 'utils/SafeStorage'

const MainLayout: FC = () => {
  const navigate = useNavigate()
  const [showOnboarding, setShowOnboarding] = useState(false)

  useEffect(() => {
    const survey = safeLocalStorage.getItem('showSignupSurvey') === 'true'
    safeLocalStorage.removeItem('showSignupSurvey')
    if (survey) {
      return navigate("/app/form/nDKBsUjD")
    }
    const offer = safeLocalStorage.getItem('offerOnboarding') === 'true'
    safeLocalStorage.removeItem('offerOnboarding')
    setShowOnboarding(offer)
  }, [])

  return (
    <PnlSyncProvider>
      <OutboundSyncProvider>
        <Modal open={showOnboarding} close={() => setShowOnboarding(false)}>
          <div className="flex flex-col items-center justify-center p-4 bg-white rounded-xl gap-4 w-[32rem]">
            <h1 className="text-2xl font-bold text-center">Welcome to Third Party Profits!</h1>
            <p className="text-center text-lg">We're happy to have you onboard. If you have any questions, feel free to reach out to us.</p>
            <div className="flex justify-center w-full gap-4">
              <button
                className="button-primary"
                onClick={() => {
                  window.open('https://discord.gg/dmfXm5sSdU', '_blank')
                  setShowOnboarding(false)
                }}
              >
                Join our Discord
              </button>
              <button
                className="button-primary"
                onClick={() => {
                  window.open('https://calendly.com/info-nplj/third-party-profits', '_blank')
                  setShowOnboarding(false)
                }}
              >
                Book a Call
              </button>
              <button
                className="button-primary"
                onClick={() => {
                  window.open('https://www.youtube.com/playlist?list=PLKV3eQ3MhLrRAVZRpy6okA27Wyow7HKRV', '_blank')
                  setShowOnboarding(false)
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </Modal>
        <SearchProvider>
          <div className="h-full flex w-full overflow-hidden">
            <Sidebar
              buttons={[
                // { icon: 'PnL', name: 'P&L', path: '/app/pnl' },
                {
                  icon: 'Suppliers',
                  name: 'Suppliers',
                  path: '/app/suppliers',
                  subRoutes: [
                    {
                      icon: 'SupplierSettings',
                      name: 'Supplier Accounts',
                      path: '/app/suppliers/settings',
                    },
                    {
                      icon: 'SupplierLeads',
                      name: 'Supplier Leads',
                      path: '/app/suppliers/leads',
                    },
                  ],
                },
                { icon: 'Barcode', name: 'Supply Chain Scanner', path: '/app/housed/master-catalog' },
                { icon: 'Folder', name: 'Master Product Folder', path: '/app/housed/housed' },
                { icon: 'Restocking', name: 'Restocking', path: '/app/restocking' },
                {
                  icon: 'POs',
                  name: 'Purchase Orders',
                  path: '/app/purchase-orders',
                  subRoutes: [
                    {
                      icon: 'WorkingPOs',
                      name: 'Working',
                      path: '/app/purchase-orders/working',
                    },
                    {
                      icon: 'POConfirmation',
                      name: 'Confirmation',
                      path: '/app/purchase-orders/open',
                    },
                    {
                      icon: 'CompletedPOs',
                      name: 'Completed',
                      path: '/app/purchase-orders/closed',
                    },
                  ],
                },
                {
                  icon: 'Warehouse',
                  name: 'Warehouse',
                  path: '/app/warehouse',
                  subRoutes: [
                    { icon: 'Truck', name: 'Inbound', path: '/app/warehouse/inbound' },
                    /*{ icon: 'Warehouse', name: 'Inventory', path: '/app/warehouse/inventory' },
                    { icon: 'TruckOut', name: 'Outbound', path: '/app/warehouse/outbound' },*/
                  ],
                },
                { icon: 'Inventory', name: 'Inventory', path: '/app/catalog' },
                {
                  icon: 'Pencil',
                  name: 'Settings',
                  path: '/app/preferences',
                  subRoutes: [
                    { icon: 'Buyers', name: 'Buyers', path: '/app/preferences/housed-buyers' },
                    {
                      icon: 'PrepCost',
                      name: 'Prep Expenses',
                      path: '/app/preferences/prep-costs',
                    },
                  ],
                },
                {
                  icon: 'Star',
                  name: 'Features',
                  path: '/app/tutorial',
                  subRoutes: [
                    {
                      icon: 'School',
                      name: 'Software Tutorials',
                      path: '/app/tutorial',
                    },
                  ],
                },
              ]}
            />
            <div className="flex flex-col grow h-full overflow-hidden">
              <div className="flex">
                <Header />
              </div>
              <div className="flex grow w-full overflow-y-auto">
                <Outlet />
              </div>
            </div>
          </div>
        </SearchProvider>
      </OutboundSyncProvider>
    </PnlSyncProvider>
  )
}

export default MainLayout
