import { CloseIcon } from 'assets/icons'
import Empty from 'components/Empty'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import { useMemo, useState } from 'react'
import { ExcelUpload } from 'utils/excelUpload'
import { useDropzone } from 'react-dropzone'
import Loader from 'components/loaders/Loader'
import BadgeSelect from 'components/BadgeSelect'

interface ColumnMappingEntry {
  key: string
  default: string
}

interface R {
  result: any[] | File
  columnMappings?: Record<string, ColumnMappingEntry>
}

type ExcelUploadType = (typeof ExcelUpload)['prototype']

interface P<T extends ExcelUploadType = ExcelUploadType> {
  withColumnMapping?: boolean
  allRequired?: boolean
  defaultValueChoices?: Record<string, { noText?: boolean; choices: string[] }>
  respondWithFile?: boolean
  template: T
  additionalInfo?: string
}

const BulkUpload: AwaitableModal<R, P> = ({ open, onCancel, resolve, initialData: { template, respondWithFile, additionalInfo, withColumnMapping, allRequired, defaultValueChoices } }) => {
  const [result, setResult] = useState<any[]>()
  const [file, setFile] = useState<File>()
  const [columnMappings, setColumnMappings] = useState<Record<string, string>>({})
  const [defaultValues, setDefaultValues] = useState<Record<string, string>>({})
  const [processingFile, setProcessingFile] = useState(false)

  const downloadTemplate = async (e: any) => {
    template.downloadTemplate()
  }

  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    if (!file) return
    setProcessingFile(true)
    try {
      const result = await template.load(file as File, respondWithFile ? 5 : undefined, withColumnMapping)
      setResult(result)
      setFile(file as File)
    } catch (err: any) {
      handleError(err)
    }
    setProcessingFile(false)
  }

  const onBulkUpload = async (e: any) => {
    template
      .upload()
      .then(async (res) => {
        setProcessingFile(true)
        try {
          const result = await template.load(res as File, respondWithFile ? 5 : undefined, withColumnMapping)
          setResult(result)
          setFile(res as File)
        } catch (err: any) {
          handleError(err)
        }
        setProcessingFile(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const templateColumns = useMemo(() => template.schema.map((col) => col.name), [template])

  const onResolve = () => {
    if (withColumnMapping && allRequired && templateColumns.some((c) => !columnMappings[c] && !defaultValues[c])) {
      handleError('Please map all columns')
      return
    }
    const columnMappingsReversed = withColumnMapping
      ? Object.entries(columnMappings).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [v]: {
              key: k,
              default: defaultValues[k],
            },
          }),
          {} as Record<string, ColumnMappingEntry>
        )
      : undefined
    Object.entries(defaultValues).forEach(([k, v]) => {
      if (!columnMappingsReversed) return
      const isKey = Object.values(columnMappingsReversed).find((v) => v.key === k)
      if (!isKey) {
        columnMappingsReversed[k] = {
          key: k,
          default: v,
        }
      }
    })
    resolve(!result || !file ? undefined : { result: respondWithFile ? file : result, columnMappings: columnMappingsReversed })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true })

  const firstResult = result ? result.find(Boolean) : undefined

  const unknownUnmappedColumns = useMemo(() => !!firstResult ? Object.keys(firstResult).filter((c) => !Object.values(columnMappings).includes(c)) : [], [columnMappings, firstResult])

  return (
    <Modal open={open} close={onCancel}>
      <div className="bg-white flex flex-col rounded-xl overflow-hidden divide-y divide-y-slate-200 relative !max-h-[calc(100vh-2rem)] h-full !max-w-[calc(100vw-2rem)] w-full">
        <header className="!mb-0 flex items-center justify-between gap-10">
          <div className="flex flex-col gap-1">
            <span>Bulk Upload</span>
            {!!additionalInfo && <span className="text-base text-slate-500">🛈 {additionalInfo}</span>}
            <span className="text-sm text-slate-500">Make Sure To Have The Data Formatted Correctly (i.e. remove currency symbols), Date Format Should be YYYY-MM-DD</span>
          </div>
          <CloseIcon onClick={onCancel} className="rounded-full hover:bg-slate-200 p-2 cursor-pointer w-10 h-10" />
        </header>
        {processingFile && (
          <Modal open={true} close={() => {}}>
            <div className="w-48 text-center flex flex-col items-center justify-center bg-white rounded-xl gap-2 p-3">
              <Loader />
              <span className="text-sm text-slate-500 font-semibold">Please wait while we process the file...</span>
            </div>
          </Modal>
        )}
        {result ? (
          <>
            <input {...getInputProps()} className="hidden" />
            <div className="grid w-full h-full overflow-y-auto">
              <div className="grid p-6 gap-4 w-full" {...getRootProps()}>
                <h3>Preview</h3>
                <div className="w-full h-full overflow-x-auto border border-slate-200 rounded-2xl">
                  <table className="w-full border border-slate-200 rounded-2xl [&_td]:!p-3 [&_th]:!p-3 [&_th]:border min-w-max h-max min-h-full">
                    <thead>
                      <tr>
                        {!firstResult && <th>Error reading file headers, row 1 of the file should be the header/title of the column</th>}
                        {!!firstResult && Object.keys(firstResult).map((k) => {
                          const columnMap = Object.entries(columnMappings).find(([_, v]) => v === k)
                          const mappedColumn = columnMap?.[0]
                          return (
                            <th key={k} className="text-left">
                              {mappedColumn ? (
                                <b>
                                  {k} &gt;&gt; {mappedColumn}
                                </b>
                              ) : (
                                k
                              )}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {result.slice(0, 10).map((r, row) => (
                        <tr key={`row_${row}`}>
                          {Object.values(r).map((v: any, col) => (
                            <td key={`row_${row}_col_${col}`} className="text-left select-text">
                              {v ? v.toString() : '---'}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {templateColumns.length > 0 && (
                  <div className="grid gap-2">
                    <div className="flex flex-col items-start gap-1">
                      <h3 className="text-2xl">Missing Columns</h3>{' '}
                      {allRequired &&
                        (templateColumns.some((c) => !columnMappings[c] && !defaultValues[c]) ? (
                          <p className="text-red-500 font-medium text-lg">
                            ALL COLUMNS NEED TO BE MAPPED
                            <br />
                            <br />
                            <span className="text-slate-950">Map Columns Here ⬇</span>
                          </p>
                        ) : null)}
                    </div>
                    <ul className="flex flex-col gap-2 divide-y divide-y-slate-200 w-max">
                      {templateColumns.map((c) => (
                        <li key={c} className="flex gap-4 items-center pt-2 w-full">
                          <span>{c}</span>
                          <span className="font-medium">&lt;&lt;</span>
                          {!defaultValueChoices?.[c]?.noText && (
                            <>
                              <BadgeSelect
                                selected={columnMappings[c] || `None`}
                                badges={["None", ...unknownUnmappedColumns]}
                                editable
                                onSelect={(newValue) => {
                                  setColumnMappings((old) => {
                                    if (newValue === "None") {
                                      const { [c]: _, ...rest } = old
                                      return rest
                                    }
                                    return { ...old, [c]: newValue }
                                  })
                                }}
                                singleColor={defaultValues[c] ? 'green' : (allRequired && !defaultValues[c]) ? 'red' : 'blue'}
                              />
                              or
                            </>
                          )}
                          {defaultValueChoices?.[c]?.choices ? (
                            <BadgeSelect
                              selected={defaultValues[c] || (defaultValueChoices[c].noText ? `Select ${c}` : `Select Default Value`)}
                              badges={defaultValueChoices[c].choices}
                              editable
                              onSelect={(v) => setDefaultValues((old) => ({ ...old, [c]: v }))}
                              singleColor={defaultValues[c] ? 'green' : (allRequired && !defaultValues[c]) ? 'red' : 'blue'}
                            />
                          ) : (
                            <input
                              value={defaultValues[c] || ''}
                              onChange={(e) => setDefaultValues((old) => ({ ...old, [c]: e.target.value }))}
                              className="table-input !text-base !border-slate-400"
                              placeholder="Set default value"
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <footer className="flex items-center gap-10 p-6 justify-between">
              <button className="button-destructive" onClick={onCancel}>
                Cancel
              </button>
              <div className="flex gap-4 items-center">
                <button className="button-secondary" onClick={onBulkUpload}>
                  Change
                </button>
                <button className="button-primary" onClick={onResolve}>
                  Continue
                </button>
              </div>
            </footer>
          </>
        ) : (
          <>
            <div className="p-6" {...getRootProps()}>
              <Empty text="No file uploaded" />
            </div>
            <footer className="flex gap-10 items-center p-6 justify-between">
              <button className="button-destructive" onClick={onCancel}>
                Cancel
              </button>
              <div className="flex gap-4 items-center">
                {!withColumnMapping && (
                  <button className="button-secondary" onClick={downloadTemplate}>
                    Download Template
                  </button>
                )}
                <button className="button-primary" onClick={onBulkUpload}>
                  Upload
                </button>
              </div>
            </footer>
          </>
        )}
        {isDragActive && <div className="absolute inset-0 flex items-center justify-center backdrop-blur-xl pointer-events-none">Drop here ...</div>}
      </div>
    </Modal>
  )
}

export default BulkUpload
