import { ColumnDef } from '@tanstack/react-table'
import { keepaStatsTableDef } from 'components/keepaStats/tableDef'
import { WorkingPurchaseOrderItem } from 'types/purchaseOrders'

const columnDef: ColumnDef<WorkingPurchaseOrderItem & { image: string }>[] = [
  {
    id: 'image',
    header: 'Image',
    size: 60,
  },
  {
    id: 'asin',
    header: 'ASIN',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'title',
    header: 'Vendor Title',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'amazonTitle',
    header: 'Title',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'upc',
    header: 'UPC',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'vendorSKU',
    header: 'Vendor SKU',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'unitCost',
    header: 'Unit Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'targetPrice',
    header: 'Ask Price',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'totalCogs',
    header: 'Total COGs',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'supplier',
    header: 'Supplier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'gross',
    header: 'Profit per Sale',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'totalGross',
    header: 'Total Profit',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'margin',
    header: 'Margin',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'roi',
    header: 'ROI',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'buyQty',
    header: 'Buy Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'bundle',
    header: 'Bundle Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'sellable',
    header: 'Sellable Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'prepCost',
    header: 'Prep Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'sku',
    header: 'MSKU',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'casePack',
    header: 'Case Pack',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'cases',
    header: 'Cases',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'brand',
    header: 'Brand',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'price',
    header: 'Sell Price',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'net',
    header: 'Net Proceeds',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'weight',
    header: 'Weight',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'asinCost',
    header: 'ASIN Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'salesRank',
    header: 'BSR',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'salesCategory',
    header: 'Category',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'revenue',
    header: 'Total Revenue',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'order_type',
    header: 'Order Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'notes',
    header: 'Notes',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'productionCost',
    header: 'Production Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'shippingCost',
    header: 'Shipping Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'landedCost',
    header: 'Landed Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'fees',
    header: 'Total Fees',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'group',
    header: 'Group',
    meta: {
      filters: 'color',
    },
  },
  {
    id: 'storageType',
    header: 'Storage Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'buyer',
    header: 'Buyer',
    meta: {
      filters: 'string',
    },
  },
  /*{
    id: 'estDailySalesVelocity',
    header: 'EST Daily Sales Velocity',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'daysUntilReorder',
    header: 'Days  Until Next Reorder',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'qtyToReorderOnRedYellow',
    header: 'QTY To Reorder ON Red/Yellow',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'supplyChainQty',
    header: 'Supply Chain QTY',
    meta: {
      filters: 'number',
    },
  },*/
  ...(keepaStatsTableDef as any),
]

export default columnDef
