import { ColumnDef } from '@tanstack/react-table'
import { keepaStatsTableDef } from 'components/keepaStats/tableDef';
import { MasterUPCCatalogItem } from 'types/housedAsins'

const columnDef: ColumnDef<MasterUPCCatalogItem>[] = [
  {
    id: 'hasHoused',
    header: 'In ASIN Assets',
    minSize: 184,
    meta: {
      queryField: "hasHousedBool",
      filters: 'boolean',
    },
  },
  {
    id: 'image',
    header: 'Image',
    size: 60,
  },
  {
    id: 'asin',
    header: 'ASIN',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'group',
    header: 'Group',
    meta: {
      filters: 'color',
    },
  },
  {
    id: 'amazonTitle',
    header: 'Amazon Title',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'supplier',
    header: 'Supplier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'sellable',
    header: 'Estimated Sales',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'unitCost',
    header: 'Unit Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'bundle',
    header: 'Bundle Qty',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'asinCost',
    header: 'ASIN Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'shippingCost',
    header: 'Shipping Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'landedCost',
    header: 'Landed Cost',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'price',
    header: 'Sell Price',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'net',
    header: 'Net Proceeds',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'gross',
    header: 'Profit per Sale',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'totalGross',
    header: 'Total Profit',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'margin',
    header: 'Profit Margin',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'roi',
    header: 'ROI',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'salesRank',
    header: 'Rank',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'salesCategory',
    header: 'Category',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'dimensionTier',
    header: 'Product Size Tier',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'storageType',
    header: 'Storage Type',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'weight',
    header: 'Weight',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'dimensions',
    header: 'Dimensions',
  },
  {
    id: 'title',
    header: 'Product Description',
    meta: {
      filters: 'string',
    },
  },
  /*{
    id: 'size',
    header: 'Size',
    meta: {
      filters: 'number',
    },
  },
  {
    id: 'uom',
    header: 'UOM',
    meta: {
      filters: 'string',
    },
  },*/
  {
    id: 'upc',
    header: 'UPC',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'vendorSKU',
    header: 'Item #',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'catalogName',
    header: 'Catalog name',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'casePack',
    header: 'Case Pack',
    meta: {
      filters: 'number',
    },
  },
  /*{
    id: 'caseCost',
    header: 'Case Cost',
    meta: {
      filters: 'number',
    },
  },*/
  {
    id: 'searchStatus',
    header: 'ASIN Search Status',
    meta: {
      filters: 'string',
    },
  },
  {
    id: 'notes',
    header: 'Notes',
    meta: {
      filters: 'string',
    },
  },
  ...(keepaStatsTableDef as any),
];

export default columnDef
