import { DetailedHTMLProps, FC, ImgHTMLAttributes } from "react"
import AsinPicture from "./img"

interface Props extends Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "src"> {
    asin: string,
}

const FullAmazonProductImage: FC<Props> = ({asin, className, style, ...props}) => {
    return (
        <AsinPicture asin={asin} title={props.alt} size={500} className={className} style={style} {...props} />
    )
}

export default FullAmazonProductImage