import { FC } from 'react'
import { Row } from '@tanstack/react-table'
import TR from './TR'
import TD from './TD'

interface props {
  row: Row<undefined>
}

const TableLoadingRow: FC<props> = ({ row }) => {
  return (
    <TR key={row.id}>
      {row.getVisibleCells().map((cell) => (
        <TD key={cell.id} cell={cell}>
          <div className="animate-pulse h-6 bg-surface-secondary rounded-md w-full" />
        </TD>
      ))}
    </TR>
  )
}

/*const TableLoadingRow: FC<props> = ({ row }) => {
  return (
    <TR key={row.id} className="animate-pulse !h-12">
      <div className="flex w-full p-2 items-center">
        <div className="h-6 bg-surface-secondary rounded-md w-full" />
      </div>
    </TR>
  )
}*/

export default TableLoadingRow
