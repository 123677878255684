import { VirtualItem, Virtualizer } from "@tanstack/react-virtual";
import { createContext, FC, PropsWithChildren, useContext } from "react";

interface Props {
    rowVirtualizer: Virtualizer<HTMLDivElement, Element>
    virtualRow: VirtualItem
}

const Context = createContext<Props>({} as Props)

export const useRow = () => useContext(Context)

export const RowProvider: FC<PropsWithChildren<Props>> = ({ rowVirtualizer, virtualRow, children }) => {
    return (
        <Context.Provider value={{ rowVirtualizer, virtualRow }}>
            {children}
        </Context.Provider>
    )
}