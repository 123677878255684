import { signInWithCustomToken } from 'firebase/auth'
import { FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import firebase from '../../../config/firebase'
import api from 'api'
import { FirebaseError } from 'firebase/app'
import { userData } from 'Root'
import { safeLocalStorage } from 'utils/SafeStorage'
import { logEvent } from 'firebase/analytics'

const RegisterPage = () => {
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string>()

  const passwordValid = password.length >= 8 && password.match(/[A-Z]/g) && password.match(/[0-9]/g)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    if (!email) return
    if (!passwordValid) return setError('Password must be at least 8 characters long and contain at least one number and one capital letter')
    if (!name) return setError('Please enter your name')
    if (!phone) return setError('Please enter your phone number')
    setError(undefined)
    api
      .signup({ email, password, phone: phone?.replaceAll(" ", "") || "", name })
      .then(({token}) => {
        logEvent(firebase.analytics, 'sign_up', { method: 'email' })
        safeLocalStorage.setItem('firstOpen', 'true')
        userData.current?.setUser(old => old && ({...old, name, email}))
        return signInWithCustomToken(firebase.auth, token)
      })
      .catch((e) => {
        setSubmitting(false)
        const err = e as FirebaseError
        switch (err.code) {
          case 'auth/invalid-email':
            setError('Please enter a valid email address')
            break
          case 'auth/user-disabled':
            setError('This account has been disabled')
            break
          case 'auth/user-not-found':
            setError('This account does not exist')
            break
          case 'auth/wrong-password':
            setError('Incorrect password')
            break
          case 'auth/invalid-login-credentials':
            setError('Invalid login credentials')
            break
          case 'auth/email-already-exists':
            setError('An account with this email already exists')
            break
          default:
            setError('An unknown error occurred')
        }
      })

    return false
  }

  return (
    <div className="flex h-full w-full p-8 items-center justify-center">
      <div className="flex flex-col p-8 gap-8 rounded-lg bg-white w-[32rem]">
        <h1 className="text-brand-primary">Sign up</h1>
        <form className="flex flex-col w-full gap-8" name="signup" onSubmit={handleSubmit}>
          <input placeholder="Name" value={name} onChange={(e) => setName(e.currentTarget.value)} className="grow p-5 default rounded-md bg-slate-50 border border-slate-100 text-base-900 text-base" />
          <input placeholder="Email" value={email} onChange={(e) => setEmail(e.currentTarget.value)} className="grow p-5 default rounded-md bg-base-50 border border-base-100 text-base-900 text-base" />
          <input
            placeholder="Phone Number"
            value={phone}
            type="tel"
            onChange={(e) => setPhone(e.currentTarget.value)}
            className="grow p-5 default rounded-md bg-base-50 border border-base-100 text-base-900 text-base"
          />
          <input
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            className="grow p-5 rounded-md default bg-base-50 border border-base-100 text-base-900 text-base"
          />
          <p className="text-base-900 text-center text-xs">
            All fields are required. By clicking 'Register', you are indicating that you have read and agree to the{' '}
            <Link className="text-brand-primary hover:underline" to="/terms-of-service">
              Terms of Service
            </Link>{' '}
            and 
            <Link className="text-brand-primary hover:underline" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>
          <button className="button-blue" children={submitting ? '...' : 'Register'} disabled={!email || submitting} />
        </form>
        {error && <p className="text-rose-500 text-center">{error}</p>}
        <div>
          <p className="text-base-900 text-center pt-2">
            Already have an account?{' '}
            <Link className="text-brand-primary hover:underline" to="/auth/login">
              Log in
            </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default RegisterPage
