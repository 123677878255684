import { Row } from '@tanstack/react-table'
import prepCostApi from 'api/prepCost'
import Icon from 'assets/icons/iconset'
import TD from 'components/tables/TD'
import TR from 'components/tables/TR'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { IPrepCost } from 'types/prepCosts'
import { RowRenderer } from 'types/tables'

interface props {
  row: Row<IPrepCost>
  setItems: Dispatch<SetStateAction<IPrepCost[] | undefined>>
}

const PrepCostItem: FC<props> = ({ row, setItems }) => {
  const item = row.original
  const [submitting, setSubmitting] = useState(false)
  const [name, setName] = useState<string>(item.name)
  const [amount, setAmount] = useState<string>(item.amount.toString())

  useEffect(() => {
    setAmount(item.amount.toString())
  }, [item])

  const onSubmit = useCallback(() => {
    if (submitting) return
    setSubmitting(true)
    const parsed = parseFloat(amount)
    if (isNaN(parsed)) {
      setAmount(item.amount.toString())
      setSubmitting(false)
      return
    } else {
      prepCostApi
        .updatePrepCost(item.id, parsed, name)
        .then((res) => {
          setItems((old) => old && old.map((i) => (i.id === item.id ? res : i)))
        })
        .finally(() => setSubmitting(false))
    }
  }, [setItems, item, amount, name, submitting])

  const cellRenderers: RowRenderer<IPrepCost> = {
    name: (cell) => (
      <TD key={cell.id} className="w-1/2" cell={cell}>
        <input
          className={['text-center rounded px-2 py-1 text-xs font-medium', item.name === 'Default' ? 'bg-[#EAF2FF] text-[#0F56CA]' : 'bg-[#ECFDF3] text-[#027A48]'].asClass}
          value={name}
          readOnly={item.name === 'Default'}
          onChange={(e) => setName(e.target.value)}
          onBlur={onSubmit}
        />
      </TD>
    ),
    amount: (cell) => (
      <TD key={cell.id} className="w-1/2 !p-1" cell={cell}>
        <div className="flex w-full rounded border border-border-primary">
          <div className="border-r border-r-border-primary p-3 bg-surface-light">
            <Icon name="Dollar" />
          </div>
          <input type="text" value={amount} onChange={(e) => setAmount(e.target.value)} onBlur={onSubmit} className="p-1 pl-3.5" placeholder="0.00" />
        </div>
      </TD>
    ),
  }

  return <TR className={['relative', submitting && 'animate-pulse'].asClass}>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default PrepCostItem
