import Icon from "components/Icon";
import { FC } from "react";
import { Outlet, useNavigate } from "react-router";
import { Link } from "react-router-dom";

const SettingsLayout: FC = () => {
    const navigate = useNavigate()

    return (
        <div className="w-full h-full p-4 flex-cool gap-4 drop-shadow-md">
            <span className="text-3xl font-medium text-base-900">Settings</span>
            <div className="w-full overflow-hidden flex flex-col lg:flex-row gap-4">
                <div className="bg-white rounded-xl flex-cool divide-y divide-y-base-200 overflow-hidden h-max">
                    <div className="p-4">
                        <span className="text-base-900 font-medium text-base">Main Menu</span>
                    </div>
                    <div className="flex-cool divide-y divide-y-base-200 overflow-y-auto">
                    <div className="flex items-center gap-3 group cursor-pointer p-4 hover:bg-base-50" onClick={() => navigate("/app/settings/amazon")}>
                        <Icon name="user-octagon" type="outline" className="w-8 h-8" />
                        <span className="text-base-900 font-medium text-base">Amazon Seller Account</span>
                    </div>
                    <div className="flex items-center gap-3 group cursor-pointer p-4 hover:bg-base-50" onClick={() => navigate("/app/settings/subscription")}>
                        <Icon name="user-octagon" type="outline" className="w-8 h-8" />
                        <span className="text-base-900 font-medium text-base">Billing</span>
                    </div>
                    <Link to="/app/tutorial" className="flex items-center gap-3 group cursor-pointer p-4 hover:bg-base-50">
                        <Icon name="user-octagon" type="outline" className="w-8 h-8" />
                        <span className="text-base-900 font-medium text-base">Software Training</span>
                    </Link>
                    </div>
                </div>
                <div className="bg-white rounded-xl flex-cool max-h-max overflow-hidden">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default SettingsLayout