import { FirebaseOptions, initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
// import { getMessaging } from 'firebase/messaging' https://github.com/firebase/firebase-js-sdk/issues/7535
import { initializeAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'
import { isDev, isLocal } from 'config/environment'
import { isDemo } from 'constants/demo'

const appFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyB8R03m8Evn3c7oy4Ye0htWr-3AAtPvxrY",
  authDomain: "app.thirdpartyprofits.com",
  projectId: "third-party-profits",
  storageBucket: "third-party-profits.appspot.com",
  // messagingSenderId: "88246241834",
  appId: "1:88246241834:web:602abf45a0167de3081153",
  measurementId: "G-7YTWCMW2VK"
}

const demoFirebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDg1DFSiE9saGyOAXnz9UIP8JxZNh4vRTw",
  authDomain: "demo.thirdpartyprofits.com",
  projectId: "third-party-profits",
  storageBucket: "third-party-profits.appspot.com",
  // messagingSenderId: "88246241834",
  appId: "1:88246241834:web:a2c851d5dfc8c45b081153",
  measurementId: "G-TCKS7N922R"
}

const firebaseConfig = isDemo ? demoFirebaseConfig : appFirebaseConfig

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
isLocal && isDev && connectAuthEmulator(auth, 'http://localhost:9099')
const db = getFirestore(app)
isLocal && isDev && connectFirestoreEmulator(db, 'localhost', 8080)
const storage = getStorage(app)
isLocal && isDev && connectStorageEmulator(storage, 'localhost', 9199)
const analytics = initializeAnalytics(app, {config: {send_page_view: false}})
const performance = getPerformance(app)

const firebase = { app, auth, db, storage, analytics, performance }

export default firebase
