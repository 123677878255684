import { ColumnDef } from '@tanstack/react-table'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import usePreferences from 'contexts/Preferences/useContext'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { ITablePreference, ITablePreferenceColumn, ITablePreferenceColumnPin, ITablesPreferences } from 'types/preferences'

import { ControlledBoard, KanbanBoard, moveCard, OnDragEndNotification } from '@caldwell619/react-kanban'
import '@caldwell619/react-kanban/dist/styles.css'
import Switch from 'components/Switch'
import Tooltip from 'components/Tooltip'
import ColumnPresets from './ColumnPresets'
import useFilteringContext from 'contexts/Filter/useFilteringContext'

interface Props {
  table: string
  columnDef: ColumnDef<any>[]
}

const pinValue = (pin: ITablePreferenceColumnPin) => {
  if (pin === 'left') return -1
  if (pin === 'right') return 1
  return 0
}

interface Card extends ITablePreferenceColumn {
  visible: boolean
}

const UpdateColumns: FC<Props> = ({ table, columnDef }) => {
  const [open, setOpen] = useState(false)
  const { setOpFilters, setOrdering } = useFilteringContext()
  const { preferences, savePreference, saving } = usePreferences()
  const [board, setBoard] = useState<KanbanBoard<Card>>({ columns: [] })

  const handleCardMove: OnDragEndNotification<Card> = useCallback((_card, source, destination) => {
    setBoard((currentBoard) => {
      return moveCard(currentBoard, source, destination)
    })
  }, [])

  const goToCard = useCallback((card: Card) => {
    setTimeout(() => {
      const selector = `data-rfd-draggable-id="${card.id}"`
      const el = document.querySelector(`[${selector}]`) as HTMLElement
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }, 100)
  }, [])

  const close = useCallback(() => {
    if (saving) return
    setOpen(false)
  }, [saving])

  useEffect(() => {
    const allColumns = columnDef.map(({ id }) => ({ id })) as Card[]
    const visible = (preferences?.tables?.[table]?.columns || allColumns).map((col) => ({ ...col, visible: true }))
    const hidden = allColumns.filter(({ id }) => !visible.find((col) => col.id === id)).map((col) => ({ ...col, visible: false }))

    const initialBoard = {
      columns: [
        {
          id: 'columns',
          title: 'Columns',
          cards: [...visible, ...hidden]
            .slice(0)
            .sort((a, b) => (a.visible === b.visible ? 0 : a.visible ? -1 : 1))
            .sort((a, b) => pinValue(a.pin) - pinValue(b.pin)),
        },
      ],
    }

    setBoard(initialBoard)
  }, [preferences, table, columnDef])

  const toggleVisibility = useCallback(
    (card: Card) => {
      setBoard((currentBoard) => {
        return {
          ...currentBoard,
          columns: [
            {
              ...currentBoard.columns[0],
              cards: currentBoard.columns[0].cards
                .map((c) => {
                  if (c.id === card.id) {
                    return { ...c, visible: !c.visible }
                  }
                  return c
                })
                .slice(0)
                .sort((a, b) => (a.visible === b.visible ? 0 : a.visible ? -1 : 1))
                .sort((a, b) => pinValue(a.pin) - pinValue(b.pin)),
            },
          ],
        }
      })
      goToCard(card)
    },
    [goToCard]
  )

  const handlePinChange = useCallback(
    (card: Card, pin: ITablePreferenceColumnPin) => {
      setBoard((currentBoard) => {
        return {
          ...currentBoard,
          columns: [
            {
              ...currentBoard.columns[0],
              cards: currentBoard.columns[0].cards
                .map((c) => {
                  if (c.id === card.id) {
                    return { ...c, pin }
                  }
                  return c
                })
                .slice(0)
                .sort((a, b) => (a.visible === b.visible ? 0 : a.visible ? -1 : 1))
                .sort((a, b) => pinValue(a.pin) - pinValue(b.pin)),
            },
          ],
        }
      })
      goToCard(card)
    },
    [goToCard]
  )

  const renderPin = useCallback(
    (card: Card) => {
      if (!card.pin)
        return (
          <div className="grid grid-cols-2 gap-1">
            <Tooltip text="Pin Left" position="top" className="z-[100000000]">
              <button className="button-secondary !p-1 rounded-full group" onClick={() => handlePinChange(card, 'left')}>
                <Icon name="Keep" className="rotate-45 group-hover:rotate-[65deg] transition-transform" />
              </button>
            </Tooltip>
            <Tooltip text="Pin Right" position="top" className="z-[100000000]">
              <button className="button-secondary !p-1 rounded-full group" onClick={() => handlePinChange(card, 'right')}>
                <Icon name="Keep" className="-rotate-45 group-hover:rotate-[-65deg] transition-transform" />
              </button>
            </Tooltip>
          </div>
        )
      return (
        <Tooltip text="Unpin" position="top" className="z-[100000000]">
          <button className={[!!card.pin ? 'button-primary' : 'button-secondary', '!p-1 rounded-full group'].asClass} onClick={() => handlePinChange(card, undefined)}>
            <Icon name="Keep" className={[card.pin === 'left' ? 'rotate-45' : '-rotate-45', 'group-hover:rotate-0 transition-transform'].asClass} />
          </button>
        </Tooltip>
      )
    },
    [handlePinChange]
  )

  const renderCard = useCallback(
    (card: Card) => {
      return (
        <div className="flex items-center gap-2 p-2 bg-white border border-gray-200 rounded-lg w-full">
          <Icon name="Drag" className="text-brand-hover" />
          <Tooltip text="Toggle visibility" position="top" className="z-[100000000]">
            <button onClick={() => toggleVisibility(card)} className="h-full flex items-center">
              <Switch checked={card.visible} />
            </button>
          </Tooltip>
          <div className="grow">{columnDef.find(({ id }) => id === card.id)?.header as string}</div>
          {renderPin(card)}
        </div>
      )
    },
    [columnDef, toggleVisibility, renderPin]
  )

  const preference = useMemo(() => {
    if (!board.columns[0]?.cards) return undefined
    return {
        columns: board.columns[0]?.cards
          .filter((col) => col.visible)
          .map((col) => {
            const def = {
              id: col.id,
              pin: col.pin,
            }
            if (!def.pin) delete def.pin
            return def
          }),
    }
  }, [board.columns])

  const handleSave = useCallback(() => {
    if (!preference) return
    const updates: ITablesPreferences = {
      [table]: preference,
    }
    savePreference('tables', updates).then((updated) => {
      if (updated) {
        setOpen(false);
        setOpFilters((old) => {
          const newFilters = { ...old }
          Object.keys(old).forEach((field) => {
            if (!preference.columns.find((col) => col.id === field)) {
              delete newFilters[field]
            }
          })
          return newFilters
        })
        setOrdering((old) => {
          if (!preference.columns.find((col) => col.id === old?.by)) {
            return undefined
          }
          return old
        })
      }
    })
  }, [savePreference, table, preference, setOpFilters, setOrdering])

  const applyPreset = useCallback(
    (pref: ITablePreference) => {
      const allColumns = columnDef.map(({ id }) => ({ id })) as Card[]
      const visible = (pref?.columns || allColumns).map((col) => ({ ...col, visible: true }))
      const hidden = allColumns.filter(({ id }) => !visible.find((col) => col.id === id)).map((col) => ({ ...col, visible: false }))

      const initialBoard = {
        columns: [
          {
            id: 'columns',
            title: 'Columns',
            cards: [...visible, ...hidden]
              .slice(0)
              .sort((a, b) => (a.visible === b.visible ? 0 : a.visible ? -1 : 1))
              .sort((a, b) => pinValue(a.pin) - pinValue(b.pin)),
          },
        ],
      }

      setBoard(initialBoard)
    } 
  , [columnDef])

  return (
    <>
      <Modal open={open} close={close}>
        <div className="flex flex-col gap-4 items-center py-4 bg-white !max-h-[calc(100vh-4rem)] !h-max overflow-hidden rounded-lg !max-w-[calc(100vw-4rem)]">
          <div className="flex items-center gap-4 w-full px-4">
            <h2 className="text-xl font-bold text-left w-full">Adjust Columns</h2>
            <ColumnPresets tableName={table} apply={applyPreset} preference={preference} />
          </div>
          <div className="grid gap-4 grow items-center h-max max-h-full overflow-y-auto w-full">
            <ControlledBoard onCardDragEnd={handleCardMove} renderCard={renderCard} allowAddColumn={false} allowAddCard={false}>
              {board}
            </ControlledBoard>
          </div>
          <div className="flex items-center justify-between w-full gap-4 mt-2 px-4">
            <button className="button-secondary" disabled={saving} onClick={close}>
              Close
            </button>
            <button className="button-primary" disabled={saving} onClick={handleSave}>
              Apply changes
            </button>
          </div>
        </div>
      </Modal>
      <button className="button-secondary" onClick={() => setOpen(true)}>
        <Icon name="Sliders" />
        Adjust Columns
      </button>
    </>
  )
}

export default UpdateColumns
