import cdnApi from "api/cdn";
import { DetailedHTMLProps, FC, ImgHTMLAttributes, useState } from "react";
import { DogFallback } from "assets";

interface Props
  extends Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    "src" | "onError" | "alt"
  > {
  asin?: string;
  title?: string;
  size?: number;
}

const AsinPicture: FC<Props> = ({ asin, title, size = 80, style, ...props }) => {
  const [error, setError] = useState(false);

  return (
    <img
      src={asin ? error ? DogFallback : cdnApi.asinImageUrl(asin, { size }) : undefined}
      alt={title || asin || ""}
      onError={() => setError(true)}
      style={{objectFit: "contain", width: size, height: size, ...style}}
      {...props}
    />
  );
};

export default AsinPicture;