import { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import AmazonProductImage from 'components/AmazonProductImage'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import { usNumber } from 'utils/formatting'
import { IPrepCost } from 'types/prepCosts'
import { WorkingPurchaseOrderItem } from 'types/purchaseOrders'
import purchaseOrdersApi from 'api/purchaseOrders'
import { RowRenderer } from 'types/tables'
import { Row } from '@tanstack/react-table'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'
import { orderTypes, storageTypes } from 'constants/badgeSelect'
import ColorGroupToggle from 'components/ColorGroupToggle'
import KeepaHistoryButton from 'components/KeepaHistoryButton'
import AsinSupplyChainButton from 'components/SupplyChain'
import useKeepaStatsRenderer from 'components/keepaStats/renderer'
import { IBuyer } from 'types/buyers'
import UPCItemDBButton from 'components/UPCItemDB'

interface props {
  row: Row<WorkingPurchaseOrderItem>
  onDone: (asin?: WorkingPurchaseOrderItem) => void
}

const Item: FC<props> = ({ onDone, row }) => {
  const item = row.original
  const { select, extra, locked } = useTableContext()
  const keepaStatsRenderer = useKeepaStatsRenderer()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...item,
  } as WorkingPurchaseOrderItem)
  const supplierNames = extra?.supplierNames || []
  const prepCosts = extra?.prepCosts || []
  const availableBuyers = extra?.availableBuyers || []

  useEffect(() => {
    setValues({ ...item })
  }, [item])

  const update = useCallback(
    (overrides?: Partial<WorkingPurchaseOrderItem>) => {
      if (submitting) return
      if (locked) return
      setSubmitting(true)
      purchaseOrdersApi
        .updateWorkingPurchaseOrder(item.id, { ...values, ...overrides })
        .then(async (res) => {
          onDone(res)
        })
        .finally(() => setSubmitting(false))
    },
    [item, locked, values, submitting, onDone]
  )

  const onSubmit = () => {
    update()
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.valueAsNumber
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const isChecked = !!select?.selected.includes(item.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<WorkingPurchaseOrderItem> = {
    selection: (cell) =>
      !!select && (
        <TD key={cell.id} onClick={() => select?.onSelectClick(item.id)} cell={cell} style={{ width: 45 }}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      ),
    targetPrice: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input type="number" readOnly={locked} className="!pl-2.5" value={values.targetPrice || ''} onChange={onNumberChange((val) => onValueChange('targetPrice', val)())} placeholder="0.00" />
        </div>
      </TD>
    ),
    price: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input
            type="number"
            readOnly={locked}
            className="!pl-2.5"
            value={values.targetSoldPrice || ''}
            onChange={onNumberChange((val) => onValueChange('targetSoldPrice', val)())}
            placeholder={(values.sellPrice || '0.00').toString()}
          />
        </div>
      </TD>
    ),
    image: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item.asin} size={48} imageSize={96} />
      </TD>
    ),
    totalCogs: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.totalCogs)}
      </TD>
    ),
    asin: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={values.asin} readOnly />
          {!!values.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
          {!!values.asin && <KeepaHistoryButton asin={values.asin} />}
          {!!item.upc && <UPCItemDBButton upc={item.upc} />}

          {!!item.asin && <AsinSupplyChainButton asin={item.asin} />}
        </div>
      </TD>
    ),
    title: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()} className="w-32">
        <input type="text" value={values.title} readOnly />
      </TD>
    ),
    amazonTitle: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()} className="w-32">
        <input type="text" value={values.amazonTitle} readOnly />
      </TD>
    ),
    supplier: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
        <BadgeSelect selected={item.supplier || ''} badges={supplierNames} onSelect={() => {}} editable={false} />
      </TD>
    ),
    gross: (cell) => (
      <TD key={cell.id} cell={cell} className={item.gross < 0 ? 'text-red-500' : item.gross > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.gross)}
      </TD>
    ),
    totalGross: (cell) => (
      <TD key={cell.id} cell={cell} className={item.totalGross < 0 ? 'text-red-500' : item.totalGross > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.totalGross)}
      </TD>
    ),
    margin: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.margin !== null ? item.margin : null, 2)}%
      </TD>
    ),
    roi: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.roi !== null ? item.roi : null, 2)}%
      </TD>
    ),
    unitCost: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <div className="table-input relative">
          <span className="absolute top-2 left-1.5 text-xs pointer-events-none">$</span>
          <input type="number" readOnly={locked} className="!pl-2.5" value={values.unitCost || ''} onChange={onNumberChange((val) => onValueChange('unitCost', val)())} placeholder="0.00" />
        </div>
      </TD>
    ),
    buyQty: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" readOnly={locked} type="number" value={values.buyQty} onChange={onNumberChange((val) => onValueChange('buyQty', val)())} placeholder="0" />
      </TD>
    ),
    bundle: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" readOnly type="number" value={values.bundle} placeholder="0" />
      </TD>
    ),
    sellable: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" readOnly type="number" value={values.sellable} placeholder="0" />
      </TD>
    ),
    upc: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" type="text" value={values.upc} readOnly />
      </TD>
    ),
    vendorSKU: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" value={values.vendorSKU} readOnly />
      </TD>
    ),
    prepCost: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.prepCost || ''}
            badges={prepCosts?.map((p: IPrepCost) => p.name) || []}
            onSelect={(prepCost) => {
              setValues((old) => ({ ...old, prepCost }))
              update({ prepCost })
            }}
            singleColor="blue"
          />
          <span>${usNumber(prepCosts?.find((p: IPrepCost) => p.name === values.prepCost)?.amount, 2)}</span>
        </div>
      </TD>
    ),
    order_type: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.order_type || ''}
            badges={orderTypes}
            onSelect={(order_type: any) => {
              setValues((old) => ({ ...old, order_type }))
              update({ order_type })
            }}
            editable={!locked && !submitting}
          />
        </div>
      </TD>
    ),
    sku: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" value={values.sku} readOnly />
      </TD>
    ),
    casePack: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" type="number" readOnly value={values.casePack} placeholder="0" />
      </TD>
    ),
    cases: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" type="number" readOnly value={values.cases} placeholder="0" />
      </TD>
    ),
    brand: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.brand}
      </TD>
    ),
    net: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.net)}
      </TD>
    ),
    weight: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.weight)}
      </TD>
    ),
    asinCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.asinCost)}
      </TD>
    ),
    salesRank: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.salesRank, 0)}
      </TD>
    ),
    salesCategory: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.salesCategory}
      </TD>
    ),
    revenue: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.revenue)}
      </TD>
    ),
    notes: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3">
        <input className="table-input" readOnly={locked} type="text" value={values.notes} onChange={(e) => onValueChange('notes', e.currentTarget.value)()} placeholder="" />
      </TD>
    ),
    productionCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.productionCost)}
      </TD>
    ),
    shippingCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.shippingCost)}
      </TD>
    ),
    landedCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.landedCost)}
      </TD>
    ),
    fees: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.fees)}
      </TD>
    ),
    group: (cell) => (
      <TD key={cell.id} cell={cell}>
        <ColorGroupToggle value={values.group} onChange={(group) => onValueChange('group', group)(true)} disabled={submitting} />
      </TD>
    ),
    storageType: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect selected={values.storageType || ''} badges={storageTypes} onSelect={(storageType: any) => {}} editable={false} />
        </div>
      </TD>
    ),
    buyer: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell}>
        <BadgeSelect
          selected={values.buyer || ''}
          badges={availableBuyers?.map((buyer: IBuyer) => buyer.name) || []}
          onSelect={() => {}}
          editable={false}
        />
      </TD>
    ),
      supplyChainQty: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.supplyChainQty, 0)}
        </TD>
      ),
      estDailySalesVelocity: (cell) => (
        <TD key={cell.id} cell={cell}>
          {usNumber(item.estDailySalesVelocity)}
        </TD>
      ),
      daysUntilReorder: (cell) => (
        <TD key={cell.id} cell={cell}>
          <span
            className={
              [
                'px-2 py-1 rounded text-black',
                item.daysUntilReorder !== null ?
                  Math.floor(+item.daysUntilReorder) <= 10 ? 'bg-red-100' : Math.floor(+item.daysUntilReorder) <= 15 ? 'bg-yellow-100' : 'bg-green-100'
                  : null,
              ].asClass
            }
          >
            {item.daysUntilReorder === null ? null : 
            Math.floor(+item.daysUntilReorder) <= 10 ? (
              <Icon name="Warning" className="w-4 h-4 inline-block mr-1" />
            ) : Math.floor(+item.daysUntilReorder) <= 15 ? (
              <Icon name="Schedule" className="w-4 h-4 inline-block mr-1" />
            ) : (
              <Icon name="TaskDone" className="w-4 h-4 inline-block mr-1" />
            )}
            {usNumber(item.daysUntilReorder === null ? null : Math.floor(+item.daysUntilReorder), 0)}
          </span>
        </TD>
      ),
      qtyToReorderOnRedYellow: (cell) => (
        <TD key={cell.id} cell={cell}>
          {item.qtyToReorderOnRedYellow !== null ? usNumber(Math.ceil(+item.qtyToReorderOnRedYellow), 0) : null}
        </TD>
      ),
    ...keepaStatsRenderer,
  }

  return (
    <TR className={['relative', submitting && 'animate-pulse'].asClass} onBlur={onSubmit}>
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Item
