import { Cell, flexRender } from '@tanstack/react-table'
import { FC, PropsWithChildren, TdHTMLAttributes } from 'react'
import { getCommonPinningStyles } from '../pinningStyles'

interface Props {
  cell: Cell<any, unknown>
}

const TD: FC<PropsWithChildren<TdHTMLAttributes<HTMLTableCellElement> & Props>> = ({ children, cell, style, className, ...props }) => {
  return (
    <td style={{...style, ...getCommonPinningStyles(cell.column)}} data-col-id={cell.column.id} className={["[&>*]:shrink-0", className].asClass} {...props}>
      {flexRender(
        children,
        cell.getContext()
      )}
    </td>
  )
}

export default TD
