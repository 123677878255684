import { getCommonPinningStyles } from 'components/tables/pinningStyles'
import { useCallback, useMemo } from 'react'
import { EntityWithKeepaStatColumns } from 'types/keepaStats'
import { RowRenderer } from 'types/tables'
import { usNumber } from 'utils/formatting'
import { keepaStatsTableDef } from './tableDef'
import { Cell } from '@tanstack/react-table'
import TD from 'components/tables/TD'

const keys = keepaStatsTableDef.map((column) => column.id).filter((key) => !!key) as string[]

type NumberFormat = "price" | "percentage" | undefined

const useKeepaStatsRenderer = () => {
  const renderValue = useCallback((cell: Cell<EntityWithKeepaStatColumns, EntityWithKeepaStatColumns>) => {
    const numberType = (cell.column.columnDef.meta as {numberFormat: NumberFormat}).numberFormat;
    const rawValue = cell.row.original[cell.column.id as keyof EntityWithKeepaStatColumns]
    switch (numberType) {
      case "price":
        return "$" + usNumber(rawValue, 2)
      case "percentage":
        return usNumber(rawValue, 2) + "%"
      default:
        return usNumber(rawValue, 0)
    }
  }, [])

  const singleRenderer = useCallback((cell: Cell<EntityWithKeepaStatColumns, EntityWithKeepaStatColumns>) => (
      <TD key={cell.id} className="!py-1.5 !px-3" cell={cell}>
        <input type="text" readOnly className="table-input" value={renderValue(cell)} placeholder="0" />
      </TD>
  ), [renderValue])

  const renderer: RowRenderer<EntityWithKeepaStatColumns> = useMemo(() => keys.reduce((acc, key) => ({
    ...acc,
    [key]: singleRenderer,
  }), {}), [singleRenderer])
  
  return renderer
}

export default useKeepaStatsRenderer
