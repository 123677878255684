import firebase from 'config/firebase'
import axios from './axios'
import { AxiosInstance } from 'axios'
import { User } from 'firebase/auth'
import { BasePricePreference, CreateSavedPreference, SavedPreference, UpdateSavedPreference } from 'types/user'
import { safeLocalStorage } from 'utils/SafeStorage'

class Api {
  axios: AxiosInstance
  constructor() {
    this.axios = axios
  }

  signup = async (data: { email: string; password: string; name: string, phone: string}) => {
    const sp = new URLSearchParams(window.location.search)
    const plan = sp.get('plan')
    const period = sp.get('period')
    const subscription = { plan, period }

    safeLocalStorage.setItem("showSignupSurvey", "true")
    safeLocalStorage.setItem("offerOnboarding", "true")

    // @ts-ignore
    const FPROM = window.FPROM as { data: { tid: string; ref_id: string }; }
    let referrer = {} as { fprRef: string; fprTid: string; }
    const fprReferrer = FPROM?.data?.ref_id
    if (fprReferrer) {
      const ref = fprReferrer
      // @ts-ignore
      const tid = window.FPROM?.data?.tid
      if (tid) {
        referrer = { fprRef: ref, fprTid: tid }
      }
    }
  
    return this.axios.post('/auth/signup', {...data, ...referrer, subscription}, {withCredentials: true}).then((res) => res.data as { token: string; user: User });
  }

  getReferralEmail = async (code: string) => {
    return this.axios.get('/invited/' + code).then((res) => res.data)
  }

  getUser = async (userId: string = 'me') => {
    return this.axios.get('/user/' + userId).then((res) => res.data)
  }

  getAmazonAuthUri = () => {
    return this.axios.get('/user/amazon/auth-uri').then((res) => res.data)
  }

  authorizeAmazon = (code: string, sellerId: string, state: string) => {
    return this.axios.put('/user/amazon/code', { code, sellerId, state }).then((res) => res.data)
  }

  getAmazonProductImage = async (asin: string) => {
    return this.axios.get<string | null>('/amazon/products/pictures/'+asin, {params: {nonce: 1}}).then(async res => {
      const link = res.data
      if (!link) return null
      return { asin, url: link }
    })
  }

  subscribeToNewsletter = async (data: {email: string, fullName: string, monthlyRevenue: string, businessModel: string, message: string}) => {
    return this.axios.post('/emailSubscribers/subscribe', data)
  }

  selectPlan = async (plan: string, period: string) => {
    return this.axios.post(`/subscriptions/${plan}/${period}`).then((res) => res.data.url as string | undefined)
  }

  cancelSubscription = async () => {
    return this.axios.delete('/subscriptions')
  }

  openBillingPortal = async () => {
    return this.axios.get('/subscriptions/billing-portal').then((res) => res.data.url as string)
  }

  getBasePricePreferences = async () => {
    return this.axios.get('/user/base-price-preferences').then((res) => res.data as BasePricePreference)
  }

  updateBasePricePreferences = async <T extends keyof BasePricePreference>(key: T, value: BasePricePreference[T]) => {
    return this.axios.put('/user/base-price-preferences', {key, value}).then((res) => res.data as BasePricePreference)
  }

  getSavedPreferences = async <T = unknown>(group: string) => {
    return this.axios.get('/user/saved-preferences', {params: {group}}).then((res) => res.data as SavedPreference<T>[])
  }

  createSavedPreference = async (input: CreateSavedPreference) => {
    return this.axios.post('/user/saved-preferences', input).then((res) => res.data as SavedPreference)
  }

  updateSavedPreference = async ({id, ...input}: UpdateSavedPreference & {id: string}) => {
    return this.axios.put('/user/saved-preferences/' + id, input).then((res) => res.data as SavedPreference)
  }

  deleteSavedPreference = async (id: string) => {
    return this.axios.delete('/user/saved-preferences/' + id)
  }

  log = async (data: {message: string, data: any, type: string, stack?: string}) => {
    const uid = firebase.auth.currentUser?.uid;
    const pathname = window.location.pathname
    const userAgent = navigator.userAgent
    return this.axios.post('/logs', {pathname, userAgent, ...data, uid})
  }
}

const api = new Api()

export default api
