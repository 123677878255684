import { Popover } from '@base-ui-components/react/popover'
import Icon from 'assets/icons/iconset'
import { FC, useEffect, useRef, useState } from 'react'
import { modalContainer } from 'utils/modalContainer'

interface props {
  badges?: string[]
  singleColor?: boolean | string
  title?: string
  selected: string
  onSelect: (id: string) => void
  editable?: boolean
  multiple?: boolean
}

export const colors = [
  { name: 'red', background: '#FEF3F2', text: '#F04438' },
  { name: 'green', background: '#ECFDF3', text: '#027A48' },
  { name: 'blue', background: '#EAF2FF', text: '#0F56CA' },
  { name: 'yellow', background: '#FFF4EA', text: '#F0A30D' },
  { name: 'purple', background: '#F5ECFF', text: '#8F20FF' },
]

const BadgeSelect: FC<props> = ({ badges: _badges, selected, onSelect, editable: _editable, title, singleColor, multiple }) => {
  const [_selected, setSelected] = useState(selected)
  const editable = !!_editable && !!_badges?.length && onSelect
  const ref = useRef<HTMLButtonElement>(null)
  const close = () => ref.current?.click()

  const selectedArr = _selected
    .split(';')
    .map((s) => s.trim())
    .filter(Boolean)
  const firstSelected = selectedArr[0]

  const missingBadges = selectedArr.filter((s) => !_badges?.includes(s))

  const skipApply = !multiple

  const badges = [...(_badges || []), ...missingBadges]

  const optionIndex = badges?.indexOf(firstSelected) || 0
  const overrideColor = singleColor ? (typeof singleColor === 'string' ? colors.find((c) => c.name === singleColor) || colors[2] : colors[2]) : undefined
  const selectedColor = overrideColor || colors[(optionIndex !== -1 ? optionIndex : 0) % colors.length]
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSelected(selected)
  }, [selected])

  const onApply = () => {
    onSelect(_selected)
    close()
  }

  const onClear = () => {
    setSelected('' as string)
  }

  const onClick = (badge: string) => {
    if (skipApply) {
      onSelect(badge)
      close()
      return
    }
    setSelected((old) => {
      if (multiple) {
        const arr = old.split(';')?.map((s) => s.trim()) || []
        if (arr.includes(badge)) {
          return arr.filter((b) => b !== badge).join(';')
        } else {
          return [...arr, badge].join(';')
        }
      } else {
        return badge
      }
    })
  }

  const filteredBadges = badges?.filter((badge) => badge.toLowerCase().includes(search.toLowerCase()) || badge === _selected)

  return (
    <Popover.Root>
      <Popover.Trigger>
        <div className="flex gap-2 items-center">
          <button
            ref={ref}
            style={{ backgroundColor: selectedColor.background, color: selectedColor.text }}
            className={['px-2 py-1 rounded gap-1 flex items-center transition-colors', editable ? 'pl-2 pr-1' : 'px-2 cursor-default'].asClass}
          >
            <span className="text-xs font-medium">{firstSelected || '---'}</span>
            {editable && <Icon name="CaretDown" className="w-5 h-5 text-text-primary" />}
          </button>
          {multiple && selectedArr.length > 1 && (
            <span className="text-xs font-medium rounded-full px-1" style={{ color: selectedColor.text, backgroundColor: selectedColor.background }}>
              +{selectedArr.length - 1}
            </span>
          )}
        </div>
      </Popover.Trigger>
      {editable && (
        <Popover.Portal container={modalContainer}>
          <Popover.Positioner align="start" side="bottom" sideOffset={16}>
            <Popover.Popup className="bg-transparent p-0 border-none">
              <div className="max-h-[16rem] overflow-y-hidden p-2 translate-y-1 gap-2 flex flex-col items-center left-0 w-max bg-white rounded-lg border border-base-200">
                <div className="flex gap-2 items-center px-2 py-1.5 w-full">
                  <span className="text-sm text-text-secondary font-medium text-left grow">{title || 'Select'}</span>
                  <button className="rounded-lg hover:bg-surface-secondary group transition-colors text-text-secondary" onClick={() => ref.current?.click()}>
                    <Icon name="X" className="transition-all w-5 h-5" />
                  </button>
                </div>
                <div className="input-box relative !border-border-secondary">
                  <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder="Search" className="!px-8" />
                  <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                    <Icon name="MagnifyingGlass" className="w-5 h-5" />
                  </div>
                </div>
                <div className="flex flex-col w-full gap-1 overflow-y-auto">
                  {filteredBadges?.map((badge) => {
                    const i = badges?.indexOf(badge) || 0
                    const color = overrideColor || colors[i % colors.length]
                    return (
                      <button
                        key={badge}
                        style={{ backgroundColor: color?.background, color: color.text }}
                        className="px-2 py-1 rounded gap-1 flex items-start w-max text-left hover:opacity-90 transition-colors"
                        onClick={() => {
                          onClick(badge)
                        }}
                      >
                        <span className="text-xs font-medium text-left w-max">{badge}</span>
                        <Icon name="Check" className={['w-4 h-4', !selectedArr.includes(badge) && 'hidden'].asClass} />
                      </button>
                    )
                  })}
                </div>
                {!skipApply && (
                  <>
                    <div className="h-px w-full bg-border-secondary" />
                    <div className="flex gap-1 w-full">
                      <button className="button-tertiary grow" onClick={onClear}>
                        Clear
                      </button>
                      <button className="button-primary grow" onClick={onApply}>
                        Apply
                      </button>
                    </div>
                  </>
                )}
              </div>
            </Popover.Popup>
          </Popover.Positioner>
        </Popover.Portal>
      )}
    </Popover.Root>
  )
}

export default BadgeSelect
