import { FC, FormEvent, useEffect, useState } from 'react'
import { Supplier } from 'types/suppliers'
import suppliersApi from 'api/suppliers'
import { handleError } from 'helpers/errors'
import Icon from 'assets/icons/iconset'
import BadgeSelect from 'components/BadgeSelect'
import useAwaitableModal from 'hooks/useAwaitableModal'
import AreYouSure from 'components/modals/AreYouSure'
import Checkbox from 'components/Checkbox'
import useTableContext from 'contexts/Table/useTableContext'
import { days, hours, paymentTerms, carriers, discrepancyTimeframe } from 'constants/badgeSelect'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'

interface props {
  onUpdate: (supplier?: Supplier) => void
  row: Row<Supplier>
  onRemove: () => void
}

const SupplierRow: FC<props> = ({ onUpdate, row, onRemove }) => {
  const supplier = row.original
  const [submitting, setSubmitting] = useState(false)
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})
  const [values, setValues] = useState({
    ...supplier,
  } as Supplier)
  const [editingWebsite, setEditingWebsite] = useState(false)
  const {select, locked} = useTableContext()

  const onSubmit = (e?: FormEvent) => {
    e?.preventDefault()
    if (locked) return false
    if (submitting) return
    setSubmitting(true)
    setValues((old) => {
      if (!old.name) {
        handleError('Name is required')
        return old
      }
      suppliersApi
        .updateSupplier(supplier.id, old)
        .then(() => {
          onUpdate({ ...old })
          setEditingWebsite(false)
        })
        .finally(() => setSubmitting(false))
      return old
    })
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return () => {
      setValues((old) => ({ ...old, [key]: value }))
    }
  }

  const [favicon, setFavicon] = useState<string | null>(null)
  useEffect(() => {
    if (!values.website) return
    try {
      const url = new URL(values.website)
      const favicon = `https://www.google.com/s2/favicons?domain=${url.href}&sz=${32}`
      setFavicon(favicon)
    } catch {
      return
    }
  }, [values.website])

  const onRemoveClick = () => {
    if (submitting) return
    setSubmitting(true)
    areYouSure({
      header: 'Delete Supplier',
      description: 'Are you sure you want to delete Supplier from your account? You can’t restore the removed ones.',
      action: 'Delete',
    })
      .then(() => {
        suppliersApi
          .deleteSupplier(supplier.id)
          .then(() => {
            onRemove()
          })
          .finally(() => setSubmitting(false))
      })
      .finally(() => setSubmitting(false))
  }

  const isChecked = !!select?.selected.includes(supplier.id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<Supplier> = {
    selection: (cell) =>
      !!select && (
        <TD key={cell.id} onClick={() => select?.onSelectClick(supplier.id)} cell={cell}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      ),
    name: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex gap-1 items-center">
          <input className="table-input" readOnly={locked} type="text" value={values.name} onBlur={onSubmit} onChange={(e) => onValueChange('name', e.target.value)()} />
          <button className="rounded-lg hover:bg-surface-secondary group transition-colors hover:text-text-primary" style={{ pointerEvents: locked ? 'none' : 'auto' }} onClick={onRemoveClick}>
            <Icon name="TrashSimple" className="group-hover:opacity-100 opacity-0 transition-all w-5 h-5" />
          </button>
        </div>
      </TD>
    ),
    website: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex items-center gap-2">
          {values.website && (!editingWebsite || submitting || locked) ? (
            <a href={values.website} target="_blank" rel="noreferrer">
              {favicon && <img src={favicon} alt="favicon" className="w-6 h-6 rounded-lg" />}
              {!favicon && <Icon name="Globe" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />}
            </a>
          ) : (
            <input className="table-input" readOnly={locked} type="text" value={values.website} onBlur={onSubmit} onChange={(e) => onValueChange('website', e.target.value)()} />
          )}
          {values.website && !editingWebsite && !locked && (
            <button className="p-0.5 rounded-lg hover:bg-surface-primary group transition-colors hover:text-text-primary" onClick={() => setEditingWebsite(true)}>
              <Icon name="CaretDoubleLeft" className="group-hover:opacity-100 opacity-0 transition-all w-4 h-4" />
            </button>
          )}
        </div>
      </TD>
    ),
    accountNumber: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input
          className="table-input"
          readOnly={locked}
          type="text"
          value={values.accountNumber}
          placeholder="---"
          onBlur={onSubmit}
          onChange={(e) => onValueChange('accountNumber', e.target.value)()}
        />
      </TD>
    ),
    userName: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" readOnly={locked} type="text" value={values.userName} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('userName', e.target.value)()} />
      </TD>
    ),
    password: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" readOnly={locked} type="text" value={values.password} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('password', e.target.value)()} />
      </TD>
    ),
    orderDay: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          selected={values.orderDay || ''}
          onSelect={(day) => {
            onValueChange('orderDay', day)()
            onSubmit()
          }}
          badges={days}
          editable={!locked}
        />
      </TD>
    ),
    orderDueBy: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          selected={values.orderDueBy || ''}
          title="Select Time"
          onSelect={(day) => {
            onValueChange('orderDueBy', day)()
            onSubmit()
          }}
          badges={hours}
          editable={!locked}
        />
      </TD>
    ),
    deliveryDay: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          selected={values.deliveryDay || ''}
          onSelect={(day) => {
            onValueChange('deliveryDay', day)()
            onSubmit()
          }}
          badges={days}
          editable={!locked}
        />
      </TD>
    ),
    leadTime: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.leadTime} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('leadTime', e.target.value)()} />
      </TD>
    ),
    paymentTerms: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          selected={values.paymentTerms || ''}
          onSelect={(day) => {
            onValueChange('paymentTerms', day)()
            onSubmit()
          }}
          badges={paymentTerms}
          editable
        />
      </TD>
    ),
    paymentDate: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.paymentDate} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('paymentDate', e.target.value)()} />
      </TD>
    ),
    carrier: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          selected={values.carrier || ''}
          onSelect={(day) => {
            onValueChange('carrier', day)()
            onSubmit()
          }}
          badges={carriers}
          editable={!locked}
        />
      </TD>
    ),
    discrepancyTime: (cell) => (
      <TD key={cell.id} cell={cell}>
        <BadgeSelect
          selected={values.discrepancyTime || ''}
          onSelect={(day) => {
            onValueChange('discrepancyTime', day)()
            onSubmit()
          }}
          badges={discrepancyTimeframe}
          editable={!locked}
        />
      </TD>
    ),
    repName: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.repName} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('repName', e.target.value)()} />
      </TD>
    ),
    repPhone: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input
          className="table-input"
          type="tel"
          lang="en-US"
          readOnly={locked}
          value={values.repPhone}
          placeholder="---"
          onBlur={onSubmit}
          onChange={(e) => onValueChange('repPhone', e.target.value)()}
        />
      </TD>
    ),
    repEmail: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="email" readOnly={locked} value={values.repEmail} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('repEmail', e.target.value)()} />
      </TD>
    ),
    repBirthday: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input
          className="table-input"
          type="date"
          readOnly={locked}
          lang="en-US"
          value={values.repBirthday}
          placeholder="---"
          onBlur={onSubmit}
          onChange={(e) => onValueChange('repBirthday', e.target.value)()}
        />
      </TD>
    ),
    creditDept: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.creditDept} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('creditDept', e.target.value)()} />
      </TD>
    ),
    notes: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.notes} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('notes', e.target.value)()} />
      </TD>
    ),
    storeName: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.storeName} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('storeName', e.target.value)()} />
      </TD>
    ),
    billingAddress: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.billingAddress} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('billingAddress', e.target.value)()} />
      </TD>
    ),
    shippingAddress: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input className="table-input" type="text" readOnly={locked} value={values.shippingAddress} placeholder="---" onBlur={onSubmit} onChange={(e) => onValueChange('shippingAddress', e.target.value)()} />
      </TD>
    ),
  }

  return (
    <>
      <AreYouSureModal />
      <TR className={["relative", submitting && 'animate-pulse'].asClass}>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
    </>
  )
}

export default SupplierRow
