import { FC, PropsWithChildren, ReactNode, useMemo } from "react"
import {Tooltip as BaseTooltip} from "@base-ui-components/react/tooltip"
import {Popover as BasePopover} from "@base-ui-components/react/popover"
import { placement } from "@material-tailwind/react/types/components/menu"
// @ts-ignore
import { type Side, type Align } from "@base-ui-components/react/cjs/utils/useAnchorPositioning"
import { modalContainer } from "utils/modalContainer"

interface props {
    text: ReactNode
    position: placement
    className?: string
    open?: boolean
    setOpen?: (open: boolean) => void
    interactive?: boolean
}

const Tooltip: FC<PropsWithChildren<props>> = ({ children, text, position, className, open = undefined, setOpen, interactive = false }) => {
  const Component = useMemo(() => interactive ? BasePopover : BaseTooltip, [interactive])

  return (
    <Component.Root open={open} openOnHover delay={100} closeDelay={100} onOpenChange={setOpen}>
      <Component.Trigger className="cursor-pointer">
        {children}
      </Component.Trigger>
      <Component.Portal container={modalContainer}>
        <Component.Positioner side={position.split("-")[0] as Side} align={(position.split("-")[1] as Align) || "center"} sideOffset={16}>
          <Component.Popup className={[
            "bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs whitespace-pre-line",
            className,
          ].asClass}>
            {text}
          </Component.Popup>
        </Component.Positioner>
      </Component.Portal>
    </Component.Root>
  )
}

export default Tooltip
