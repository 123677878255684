import { Row } from '@tanstack/react-table'
import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import AmazonProductImage from 'components/AmazonProductImage'
import BadgeSelect from 'components/BadgeSelect'
import ColorGroupToggle from 'components/ColorGroupToggle'
import KeepaHistoryButton from 'components/KeepaHistoryButton'
import AsinSupplyChainButton from 'components/SupplyChain'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'
import { storageTypes } from 'constants/badgeSelect'
import useTableContext from 'contexts/Table/useTableContext'
import { FC, useEffect, useState } from 'react'
import { MasterUPCCatalogItem } from 'types/housedAsins'
import { RowRenderer } from 'types/tables'
import { usNumber } from 'utils/formatting'
import SimilarItems from './SimilarItems'
import useKeepaStatsRenderer from 'components/keepaStats/renderer'
import UPCItemDBButton from 'components/UPCItemDB'
import Tooltip from 'components/Tooltip'

interface props {
  row: Row<MasterUPCCatalogItem>
  updateItem: (item: Partial<MasterUPCCatalogItem>) => void
  nested?: boolean
}

const Item: FC<props> = ({ row, updateItem, nested = false }) => {
  const { supplier, vendorSKU, upc, unitCost, caseCost, casePack, title, uom, size } = row.original
  const item = row.original
  const { extra } = useTableContext()
  const keepaStatsRenderer = useKeepaStatsRenderer()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState<MasterUPCCatalogItem>({ ...item })
  const [showSimilar, setShowSimilar] = useState(false)

  const createHoused = extra?.onCreateHoused
  const createWorkingPO = extra?.onCreateWorkingPO

  useEffect(() => {
    setValues({ ...item })
  }, [item])

  const { length, width, height, unit } = item.dimensions || {}

  const onSubmit = async (overrides?: Partial<MasterUPCCatalogItem>) => {
    if (submitting) return
    if (!item.asin) return
    const updates = { ...values, ...(overrides || {}) }
    let bundle = Number(updates.bundle)
    if (isNaN(bundle)) bundle = 0
    let sellable = Number(updates.sellable)
    if (isNaN(sellable)) sellable = 0
    bundle = Math.floor(bundle)
    sellable = Math.floor(sellable)
    const group = updates.group || null
    const storageType = updates.storageType || null
    setSubmitting(true)
    housedAsinsApi
      .updateMasterCatalogItem(item.id, item.asin, { bundle, sellable, group, storageType })
      .then((updated) => updateItem(updated))
      .finally(() => setSubmitting(false))
  }

  const cellRenderers: RowRenderer<MasterUPCCatalogItem> = {
    supplier: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="text" value={supplier || '---'} readOnly />
      </TD>
    ),
    title: (cell) => (
      <TD key={cell.id} cell={cell}>
        <Tooltip text={title || ''} position="right" className="!z-[10000000]">
          <input type="text" value={title || '---'} readOnly />
        </Tooltip>
      </TD>
    ),
    size: (cell) => (
      <TD key={cell.id} cell={cell}>
        {size || '---'}
      </TD>
    ),
    uom: (cell) => (
      <TD key={cell.id} cell={cell}>
        {uom || '---'}
      </TD>
    ),
    upc: (cell) => (
      <TD key={cell.id} cell={cell}>
        {upc || '---'}
      </TD>
    ),
    vendorSKU: (cell) => (
      <TD key={cell.id} cell={cell}>
        {vendorSKU || '---'}
      </TD>
    ),
    unitCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        ${usNumber(Number(unitCost))}
      </TD>
    ),
    casePack: (cell) => (
      <TD key={cell.id} cell={cell}>
        {Number(casePack)}
      </TD>
    ),
    caseCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        ${usNumber(Number(caseCost))}
      </TD>
    ),
    searchStatus: (cell) => (
      <TD key={cell.id} className="!py-1.5 !px-3" cell={cell}>
        <span
          className={
            [
              'cursor-pointer px-2 py-1 rounded text-xs font-medium',
              item.searchStatus === 'not found' ? 'bg-red-100 text-red-500' : item.searchStatus === 'not synced' ? 'bg-yellow-100 text-yellow-500' : 'bg-green-100 text-green-500',
            ].asClass
          }
        >
          {item.searchStatus.toUpperCase()}
        </span>
      </TD>
    ),
    image: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell}>
        {item.asin ? <AmazonProductImage asin={item.asin} size={36} imageSize={72} className="max-w-9 max-h-9" /> : null}
      </TD>
    ),
    asin: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item.asin || 'N/A'} readOnly />
          {!!item.asin && (
            <a href={`https://amazon.com/dp/${item.asin}`} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
          {!!item.asin && <KeepaHistoryButton asin={item.asin} />}
          {!!item.upc && <UPCItemDBButton upc={item.upc} />}

          {!!item.asin && <AsinSupplyChainButton asin={item.asin} />}
        </div>
      </TD>
    ),
    amazonTitle: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell} className="w-32">
        <Tooltip text={item.amazonTitle || ''} position="right" className="!z-[100000000]">
          <input type="text" value={item.amazonTitle || ''} readOnly />
        </Tooltip>
      </TD>
    ),
    bundle: (cell) => (
      <TD key={cell.id} className="!py-1.5 !px-3" cell={cell} onBlur={() => onSubmit()}>
        <input
          className="table-input"
          readOnly={!item.asin}
          type="text"
          value={values.bundle || ''}
          onChange={(e) => setValues((old) => ({ ...old, bundle: e.target.value as unknown as number }))}
          placeholder={(item.amazonBundle || '---').toString()}
        />
      </TD>
    ),
    sellable: (cell) => (
      <TD key={cell.id} className="!py-1.5 !px-3" cell={cell} onBlur={() => onSubmit()}>
        <input
          className="table-input"
          readOnly={!item.asin}
          type="text"
          value={values.sellable || ''}
          onChange={(e) => setValues((old) => ({ ...old, sellable: e.target.value as unknown as number }))}
          placeholder={usNumber(values.keepaEstimatedSellable, 0)}
        />
      </TD>
    ),
    group: (cell) => (
      <TD key={cell.id} cell={cell}>
        <ColorGroupToggle
          value={values.group || 'white'}
          onChange={(group) => {
            setValues((old) => ({ ...old, group }))
            onSubmit({ group })
          }}
          disabled={submitting}
        />
      </TD>
    ),
    notes: (cell) => (
      <TD key={cell.id} cell={cell} className="!py-1.5 !px-3" onBlur={() => onSubmit()}>
        <input className="table-input" readOnly={submitting} type="text" value={values.notes || ''} onChange={(e) => setValues((old) => ({ ...old, notes: e.target.value }))} placeholder="" />
      </TD>
    ),
    asinCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.asinCost)}
      </TD>
    ),
    shippingCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.shippingCost)}
      </TD>
    ),
    landedCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.landedCost)}
      </TD>
    ),
    price: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.price)}
      </TD>
    ),
    net: (cell) => (
      <TD key={cell.id} cell={cell}>
        $ {usNumber(item.net)}
      </TD>
    ),
    gross: (cell) => (
      <TD key={cell.id} cell={cell} className={+(item.gross || 0) < 0 ? 'text-red-500' : +(item.gross || 0) > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.gross)}
      </TD>
    ),
    totalGross: (cell) => (
      <TD key={cell.id} cell={cell} className={+(item.totalGross || 0) < 0 ? 'text-red-500' : +(item.totalGross || 0) > 0 ? 'text-green-500' : ''}>
        $ {usNumber(item.totalGross)}
      </TD>
    ),
    margin: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.margin !== null ? item.margin : null, 2)}%
      </TD>
    ),
    roi: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.roi !== null ? item.roi : null, 2)}%
      </TD>
    ),
    dimensionTier: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.dimensionTier || 'STANDARD'}
      </TD>
    ),
    weight: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>
            {usNumber(item.weight)} <span className="font-normal">{item.weightUnit || '-'}</span>
          </span>
        </div>
      </TD>
    ),
    dimensions: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <input type="text" value={`${usNumber(length)} x ${usNumber(width)} x ${usNumber(height)} ${unit || '-'}`} readOnly />
        </div>
      </TD>
    ),
    salesRank: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <span>{item.salesRank ? usNumber(item.salesRank, 0) : '-'}</span>
        </div>
      </TD>
    ),
    salesCategory: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <input type="text" value={item.salesCategory || '---'} readOnly />
        </div>
      </TD>
    ),
    catalogName: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex flex-col gap-1 font-medium text-xs">
          <input type="text" value={item.catalogName || '---'} readOnly />
        </div>
      </TD>
    ),
    storageType: (cell) => (
      <TD key={cell.id} onBlur={(e) => e.stopPropagation()} cell={cell}>
        <div className="flex items-center gap-2 min-w-[10rem]">
          <BadgeSelect
            selected={values.storageType || ''}
            badges={storageTypes}
            onSelect={(storageType: any) => {
              setValues((old) => ({ ...old, storageType }))
              onSubmit({ storageType })
            }}
            editable={!submitting}
          />
        </div>
      </TD>
    ),
    hasHoused: (cell) => (
      <TD key={cell.id} className="!py-1.5 !px-3" cell={cell}>
        <div className="flex gap-1 items-center">
          <BadgeSelect selected={item.hasHoused ? 'Yes' : 'No'} badges={['No', 'Yes']} onSelect={() => {}} editable={false} />
          {item.hasHoused ? (
            <button className="button-secondary w-max !py-1 !px-2" onClick={() => createWorkingPO(item.id, item.asin)}>
              Add to PO
            </button>
          ) : (
            <button className="button-secondary w-max !py-1 !px-2" onClick={() => createHoused(item.id, item.asin).then(onSubmit)}>
              Save ASIN
            </button>
          )}
          {!nested && (
            <button className="!p-1 button-tertiary pointer-events-auto" onClick={() => setShowSimilar((old) => !old)}>
              <Icon name="CaretRight" className={['w-4 h-4 transition-transform', showSimilar && 'rotate-45'].asClass} />
            </button>
          )}
        </div>
      </TD>
    ),
    ...keepaStatsRenderer,
  }

  if (nested) {
    return <div className="flex w-full h-max">{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</div>
  }

  return (
      <TR className={['relative !grid w-full', submitting && 'animate-pulse', nested && '[&>td]:bg-surface-secondary'].asClass}>
        <div className="flex w-full">{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</div>
        {!nested && item.asin && <SimilarItems row={row} asin={item.asin} open={showSimilar} updateItem={updateItem} />}
      </TR>
  )
}

export default Item
