import { FC } from 'react'

interface props {
  rows?: number
}

const TableSpanner: FC<React.PropsWithChildren<props>> = ({ rows = 1, children }) => {
  return (
    <div className="w-max flex justify-start items-center p-4 !sticky left-0" style={{ height: `${rows * 3}rem` }}>
      {children}
    </div>
  )
}

export default TableSpanner
