import api from 'api'
import { AmazonImg, AmazonSoftwarePartnerImg } from 'assets'
import Logo from 'components/layout/Logo'
import { FC, useState } from 'react'

const AmazonLoginPage: FC = () => {
  const [submitting, setSubmitting] = useState(false)

  const onClick = () => {
    if (submitting) return
    setSubmitting(true)
    api.getAmazonAuthUri().then((res) => {
      window.open(res, '_self')
    })
  }

  return (
    <div className="flex flex-col w-full h-full items-center justify-center relative gap-6">
      <div className="absolute inset-0 grayscale opacity-10" style={{ backgroundImage: `url(${AmazonImg})`, backgroundSize: '3%' }} />
      <div className="flex flex-col z-10 p-4 rounded-xl bg-white drop-shadow-lg items-center justify-center gap-4">
        <img src={AmazonSoftwarePartnerImg} className="w-40" />
        <Logo className="w-40 [&>img]:!object-contain self-center pt-4 z-10" />
        <button disabled={submitting} className="button-primary text-xl !py-4 !px-10 z-10" children="Continue with Amazon Account" onClick={onClick} />
      </div>
    </div>
  )
}

export default AmazonLoginPage
