import { placement } from '@material-tailwind/react/types/components/menu'
import Tooltip from 'components/Tooltip'
import useIconsax from 'hooks/useIconsax'
import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react'

interface props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  name: string
  tooltip?: string
  active?: boolean
  size?: number
  tooltipLocation?: placement
  hasNotification?: boolean
  wrapperClassName?: string
}

const IconButton: FC<props> = ({ name, tooltip, tooltipLocation, className, hasNotification, wrapperClassName, active, size = 24, ...rest }) => {
  const SVG = useIconsax(`outline/${name}.svg`)

  const combinedClass = [active ? "fill-primary-600" : `fill-base-900 group-hover:fill-primary-600`, className].filter(Boolean).join(" ")

  if (!tooltip) {
    return (
      <button className={['button relative overflow-visible rounded-full w-min text-dark bg-white hover:bg-base-200 flex h-min p-2', className].join(' ')} {...rest}>
        {!!SVG && <SVG width={size} height={size} viewBox="0 0 24 24" className={combinedClass} />}
      </button>
    )
  }

  return (
    <Tooltip
      text={tooltip}
      className="w-max h-max bg-base-200 text-base-900 py-1 px-2 rounded z-[100000000000]"
      position={tooltipLocation || "top"}
    >
      <div className={wrapperClassName}>
        <button className={['button relative overflow-visible rounded-full w-min text-dark bg-white hover:bg-base-200 flex h-min p-2 group', className].join(' ')} {...rest}>
          {!!SVG && <SVG width={size} height={size} viewBox="0 0 24 24" className={combinedClass} />}
        </button>
      </div>
    </Tooltip>
  )
}

export default IconButton


