import { FC, HTMLProps, PropsWithChildren } from 'react'
import { useRow } from './RowProvider'

const TR: FC<PropsWithChildren<HTMLProps<HTMLTableRowElement>>> = ({ children, style, ...props }) => {
  const { rowVirtualizer, virtualRow } = useRow()

  return (
    <tr
      ref={rowVirtualizer.measureElement}
      data-index={virtualRow.index}
      style={{
        ...style,
        height: "max-content",
        display: 'flex',
        position: 'absolute',
        transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
        width: '100%',
      }}
      {...props}
    >
      {children}
    </tr>
  )
}

export default TR
