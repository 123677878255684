import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react'
import AmazonProductImage from 'components/AmazonProductImage'
import useTableContext from 'contexts/Table/useTableContext'
import Icon from 'assets/icons/iconset'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'
import { useNavigate, useParams } from 'react-router'
import { IWarehouseInboundShipmentItem } from 'types/warehouse'
import warehouseApi from 'api/warehouse'
import { IWarehouseInboundShipmentItemColumn } from 'utils/warehouse'
import { Row } from '@tanstack/react-table'
import { RowRenderer } from 'types/tables'
import Checkbox from 'components/Checkbox';

interface props {
  raw: IWarehouseInboundShipmentItem
  row: Row<IWarehouseInboundShipmentItemColumn>
  onUpdate: (item: IWarehouseInboundShipmentItem) => void
}

const Item: FC<props> = ({ onUpdate, row, raw }) => {
  const item = row.original
  const { id } = useParams() as { id: string }
  const {locked, select} = useTableContext()
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({
    ...raw,
  } as IWarehouseInboundShipmentItem)
  const navigate = useNavigate()

  const onForwardUnitQty = () => {
    const qty = values.units
    onValueChange('arrivedUnits', qty)()
    onValueChange('missing', 0)()
    onValueChange('damaged', 0)()
  }

  const onForwardCaseQty = () => {
    const qty = values.caseQty
    onValueChange('arrivedCaseQty', qty)(true)
  }

  useEffect(() => {
    setValues({ ...raw })
  }, [raw])

  const update = (overrides?: Partial<IWarehouseInboundShipmentItem>) => {
    if (submitting) return
    if (locked) return
    setSubmitting(true)
    const value = { ...values, ...overrides }
    if (value.expirationDate) {
      const date = new Date(value.expirationDate)
      date.setHours(0, 0, 0, 0)
      value.expirationDate = date.toISOString()
    }
    warehouseApi
      .updateWarehouseInboundShipmentItem(id, raw.id, value)
      .then((res) => {
        onUpdate(res)
      })
      .finally(() => setSubmitting(false))
  }

  const onSubmit = () => {
    update()
    return false
  }

  const onValueChange = (key: string, value: any) => {
    return (submit?: boolean) => {
      setValues((old) => ({ ...old, [key]: value }))
      submit && update({ [key]: value })
    }
  }

  const onNumberChange = (updater: Dispatch<SetStateAction<number | null>>) => (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (isNaN(value)) return updater(null)
    updater(value)
  }

  const listing = `https://www.amazon.com/dp/${item.asin}`

  const someArrived = item.arrivedUnits !== null || raw.arrivedCaseQty !== null
  const accountedFor = (item.arrivedUnits ?? 0) + (item.missing ?? 0) + (item.damaged ?? 0)
  const hasNotAccountedFor = someArrived && item.units - accountedFor > 0

  const expirationDate = useMemo(() => {
    if (!values.expirationDate) return ''
    const date = new Date(values.expirationDate)
    const [month, day, year] = date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-').split('-')
    return `${year}-${month}-${day}`
  }, [values.expirationDate])

  const isChecked = !!select?.selected.includes(id)
  const allSelected = !!select?.allSelected

  const cellRenderers: RowRenderer<IWarehouseInboundShipmentItemColumn> = {
    selection: (cell) => (
      !!select && (
        <TD key={cell.id} onClick={() => select?.onSelectClick(id)} cell={cell}>
          <Checkbox checked={allSelected ? !isChecked : isChecked} />
        </TD>
      )),
    invoiceNumber: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
        {item.invoiceNumber ?? "---"}
      </TD>
    ),
    image: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
        <AmazonProductImage asin={item?.asin} size={32} imageSize={32} />
      </TD>
    ),
    asin: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()} className="w-20">
        <div className="flex items-center gap-2">
          <input type="text" value={item?.asin} readOnly />
          {!!raw.asin && (
            <a href={listing} target="_blank" rel="noreferrer">
              <Icon name="AmazonLogo" className="w-6 h-6 text-brand-hover p-0.5 hover:bg-surface-secondary transition-colors rounded-lg" />
            </a>
          )}
          {/*<button className="button-primary !px-2" onClick={() => navigate(`./detail/${raw.id}`)} onBlur={(e) => e.stopPropagation()}>
            <Icon name="Info" className="w-4 h-4" />
          </button>*/}
        </div>
      </TD>
    ),
    title: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()} className="w-32">
        <input type="text" value={item.title || ""} readOnly />
      </TD>
    ),
    upcOverSKU: (cell) => (
      <TD key={cell.id} cell={cell} onBlur={(e) => e.stopPropagation()}>
        <input type="text" value={item.upcOverSKU} readOnly />
      </TD>
    ),
    casePack: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.casePack}
      </TD>
    ),
    caseOrder: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex items-center gap-4 pointer-events-none justify-between">
          <span className="pointer-events-auto">{item.caseOrder}</span>
          <button className="!p-1 button-tertiary pointer-events-auto" onClick={onForwardCaseQty}>
            <Icon name="CaretRight" className="w-4 h-4" />
          </button>
        </div>
      </TD>
    ),
    arrivedCaseQty: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input readOnly={locked} className="table-input" type="text" value={values.arrivedCaseQty || ''} onChange={onNumberChange((val) => onValueChange('arrivedCaseQty', val)())} placeholder="0" />
      </TD>
    ),
    units: (cell) => (
      <TD key={cell.id} cell={cell}>
        <div className="flex items-center gap-4 pointer-events-none justify-between">
          <span className="pointer-events-auto">{item.units}</span>
          <button className="!p-1 button-tertiary pointer-events-auto" onClick={onForwardUnitQty}>
            <Icon name="CaretRight" className="w-4 h-4" />
          </button>
        </div>
      </TD>
    ),
    arrivedUnits: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input readOnly={locked} className="table-input" type="text" value={values.arrivedUnits || ''} onChange={onNumberChange((val) => onValueChange('arrivedUnits', val)())} placeholder="0" />
      </TD>
    ),
    difference: (cell) => (
      <TD key={cell.id} cell={cell}>
        {someArrived ? item.difference : '---'}
      </TD>
    ),
    missing: (cell) => (
      <TD key={cell.id} cell={cell} className={hasNotAccountedFor ? 'bg-red-400' : ''}>
        {someArrived && item.difference ? (
          <input readOnly={locked} className="table-input" type="text" value={values.missing || ''} onChange={onNumberChange((val) => onValueChange('missing', val)())} placeholder="0" />
        ) : (
          '---'
        )}
      </TD>
    ),
    damaged: (cell) => (
      <TD key={cell.id} cell={cell} className={hasNotAccountedFor ? 'bg-red-400' : ''}>
        {someArrived && item.difference ? (
          <input readOnly={locked} className="table-input" type="text" value={values.damaged || ''} onChange={onNumberChange((val) => onValueChange('damaged', val)())} placeholder="0" />
        ) : (
          '---'
        )}
      </TD>
    ),
    bundle: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.bundle}
      </TD>
    ),
    sellable: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.sellable}
      </TD>
    ),
    prepCost: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.prepCost?.name}
      </TD>
    ),
    expirationDate: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="date" value={expirationDate} onChange={(e) => onValueChange('expirationDate', e.target.value)()} />
      </TD>
    ),
  }

  return (
    <TR className={['relative', submitting && 'animate-pulse'].asClass} onBlur={onSubmit}>
      {row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}
    </TR>
  )
}

export default Item
