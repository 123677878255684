import useAwaitableModal from "hooks/useAwaitableModal";
import { FC } from "react";
import PreviewImageModal from "./PreviewImageModal";
import AsinPicture from "./img";

interface props extends Omit<React.HTMLAttributes<HTMLImageElement>, "onClick" | "alt"> {
    asin: string,
    size: number,
    imageSize?: number
}

const AmazonProductImage: FC<props> = ({asin, size, className, imageSize, ...props}) => {
    const [request, Modal] = useAwaitableModal(PreviewImageModal, {asin})
    return (
    <>
        <Modal />
        <AsinPicture asin={asin} size={size} className={className} {...props} onClick={() => request({asin})} />
    </>
    )
}

export default AmazonProductImage