import { Column } from '@tanstack/react-table'
import { CSSProperties } from 'react'

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || window.matchMedia('not screen and (hover)').matches
}

export const isMobileDevice = () => {
  const isMobileByWidth = window.innerWidth < 768
  const isMobileByUserAgent = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  const isTouch = isTouchDevice()
  return isMobileByWidth || isMobileByUserAgent || isTouch
}

export const isTabletDevice = () => {
  const isTabletByWidth = window.innerWidth < 1024
  const isTabletByUserAgent = /iPad/i.test(navigator.userAgent)
  const isTouch = isTouchDevice()
  return isTabletByWidth || isTabletByUserAgent || isTouch
}

export const getCommonPinningStyles = <T = unknown>(column: Column<T>): CSSProperties => {
  const isTooSmall = isMobileDevice()
  const isPinned = isTooSmall && column.columnDef.id !== 'selection' ? false : column.getIsPinned()
  const isLastLeftPinnedColumn = isPinned === 'left' && column.getIsLastColumn('left')
  const isFirstRightPinnedColumn = isPinned === 'right' && column.getIsFirstColumn('right')

  return {
    boxShadow: isLastLeftPinnedColumn ? '-4px 0 4px -4px gray inset' : isFirstRightPinnedColumn ? '4px 0 4px -4px gray inset' : undefined,
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    opacity: isPinned ? 10 : 1,
    position: isPinned ? 'sticky' : 'relative',
    display: 'flex',
    alignItems: 'center',
    width: column.getSize(),
    minWidth: "max-content",
    zIndex: isPinned ? 1 : 0,
  }
}
