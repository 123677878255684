import housedAsinsApi from 'api/housedAsins'
import Icon from 'assets/icons/iconset'
import Modal from 'components/Modal'
import { handleError } from 'helpers/errors'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import useSupplierNames from 'hooks/useSupplierNames'
import { useState } from 'react'
import { CreateMasterUPCCatalogItemInput } from 'types/housedAsins'

const AddEntryModal: AwaitableModal<CreateMasterUPCCatalogItemInput> = ({ open, resolve }) => {
  const [submitting, setSubmitting] = useState(false)
  const [values, setValues] = useState({} as Record<keyof CreateMasterUPCCatalogItemInput, string>)
  const {supplierNames} = useSupplierNames()

  const handleSubmit = async () => {
    if (submitting) return
    setSubmitting(true)
    const item = {
      ...values,
      casePack: Number(values.casePack),
      unitCost: Number(values.unitCost),
      caseCost: Number(values.caseCost),
    }

    if (isNaN(item.unitCost)) {
      setSubmitting(false)
      return handleError('Please enter a valid number for unit cost.')
    }

    if (isNaN(item.casePack)) {
      setSubmitting(false)
      return handleError('Please enter a valid number for case pack.')
    }

    if (!values.caseCost) {
      item.caseCost = item.unitCost * item.casePack
    } else if (isNaN(item.caseCost)) {
      setSubmitting(false)
      return handleError('Please enter a valid number for case cost.')
    }

    housedAsinsApi
      .addMasterCatalogItem({ ...item })
      .then(() => {
        window.location.reload()
      })
      .finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={() => resolve()}>
      <div className="w-full max-w-[32rem] rounded-xl max-h-[calc(100%-4rem)] min-w-[16rem] overflow-hidden">
        <div className="flex flex-col bg-surface-primary rounded-t-xl">
          <div className="flex items-start justify-start p-6 pb-4 gap-2">
            <Icon name="Plus" className="w-6 h-6 text-brand-primary" />
            <div className="flex flex-col items-start gap-1">
              <span className="text-base text-text-primary font-medium">Add Entry</span>
              <span className="text-sm text-text-secondary">Add your supplier product information.</span>
            </div>
          </div>
          <div className="flex flex-col bg-surface-primary px-6">
            <div className="grid grid-cols-3 gap-4">
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Supplier</span>
                <select
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.supplier}
                  onChange={(e) => setValues((prev) => ({ ...prev, supplier: e.target.value }))}
                >
                  <option value="">Select Supplier</option>
                  {supplierNames?.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">UPC</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.upc}
                  onChange={(e) => setValues((prev) => ({ ...prev, upc: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Item #</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.vendorSKU}
                  onChange={(e) => setValues((prev) => ({ ...prev, vendorSKU: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Product Description</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.title}
                  onChange={(e) => setValues((prev) => ({ ...prev, title: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Size</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.size}
                  onChange={(e) => setValues((prev) => ({ ...prev, size: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Unit Of Measure</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.uom}
                  onChange={(e) => setValues((prev) => ({ ...prev, uom: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Unit Cost</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.unitCost}
                  onChange={(e) => setValues((prev) => ({ ...prev, unitCost: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Case Pack</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.casePack}
                  onChange={(e) => setValues((prev) => ({ ...prev, casePack: e.target.value }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <span className="text-xs text-text-secondary font-medium">Case Cost</span>
                <input
                  type="text"
                  className="border border-border-primary py-2.5 px-2 rounded-lg"
                  value={values.caseCost}
                  placeholder={values.unitCost && values.casePack ? `$${+values.unitCost * +values.casePack}` : '---'}
                  onChange={(e) => setValues((prev) => ({ ...prev, caseCost: e.target.value }))}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 p-4 w-full">
            <button className="button-secondary grow" disabled={submitting} onClick={() => resolve()}>
              Cancel
            </button>
            <button className="button-primary grow" disabled={submitting} onClick={() => handleSubmit()}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AddEntryModal
