import { helpdeskConfig } from "constants/helpdesk";
import { useCallback, useEffect } from "react"

const scriptText = `
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[OpenWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.openwidget.com/openwidget.js",t.head.appendChild(n)}};!n.__ow.asyncInit&&e.init(),n.OpenWidget=n.OpenWidget||e}(window,document,[].slice))
`

const useHelpDesk = () => {
    const mountChatBot = useCallback(() => {
        try {
            window.__ow = {};
            window.__ow.organizationId = helpdeskConfig.organizationId;
            window.__ow.integration_name = helpdeskConfig.integration_name;
            window.__ow.product_name = helpdeskConfig.product_name;
            document.getElementById('helpdesk-script')?.remove()
            const scriptElement = document.createElement('script')
            scriptElement.id = 'helpdesk-script'
            scriptElement.innerHTML = scriptText
            const appended = document.body.appendChild(scriptElement)

            return () => {
                appended.remove()
            }
        } catch (error) {
            console.error('Error mounting chat bot', error)
        }
    }, [])


    useEffect(() => {
        return mountChatBot()
    }, [mountChatBot])
}

export default useHelpDesk;