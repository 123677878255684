export const subscriptionPlans = [
    {
        id: "scraper",
        name: "Scraper Alone",
        isLimited: true,
        price: {
            monthly: 67,
            yearly: 697,
        }
    },
    {
        id: "all_features",
        name: "All Features",
        price: {
            monthly: 197,
            yearly: 1997,
        },
    }
]