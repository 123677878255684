import { FC, useEffect, useMemo, useState } from 'react'
import KeepaIcon from "assets/icons/iconset/keepa.png";
import Tooltip from './Tooltip'

interface props {
  asin: string
}

// maxWidth 1000 minWidth 300
// maxHeight 1000 minHeight 150
// aspect 800/250
const getSize = () => {
  let width = Math.floor(window.innerWidth / 1.2)
  let height = Math.floor(width / 800 * 250)
  if (width > 1000) {
    width = 1000
    height = 1000 / 800 * 250
  }
  if (width < 300) {
    width = 300
    height = 300 / 800 * 250
  }
  if (height > 1000) {
    return { width: 1000 / 250 * 800, height: 1000 }
  }
  if (height < 150) {
    return { width: 150 / 250 * 800, height: 150 }
  }
  return { width, height }
}

const KeepaHistoryButton: FC<props> = ({ asin }) => {
  const [open, setOpen] = useState(false)
  const link = useMemo(() => {
    const { width, height } = getSize()
    const url = new URL('https://graph.keepa.com/pricehistory.png')
    url.searchParams.append('asin', asin)
    url.searchParams.append('domain', 'com')
    url.searchParams.append('width', Math.floor(width).toString())
    url.searchParams.append('height', Math.floor(height).toString())
    url.searchParams.append('amazon', '1')
    url.searchParams.append('new', '1')
    url.searchParams.append('salesrank', '1')
    url.searchParams.append('range', '30')
    url.searchParams.append('cBackground', 'FFFFFF')
    url.searchParams.append('cFont', '101828')
    url.searchParams.append('cAmazon', 'FF9900')
    url.searchParams.append('cNew', '3366CC')
    url.searchParams.append('cUsed', '666666')

    return url.toString()
  }, [asin])

  const openKeepa = () => {
    window.open(`https://keepa.com/#!product/1-${asin}`, '_blank')
  }

  useEffect(() => {
    if (open) {
      const listener = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          setOpen(false)
        }
      }
      const clickListener = (e: MouseEvent) => {
        setOpen(false)
      }
      window.addEventListener('keydown', listener)
      window.addEventListener('click', clickListener)
      return () => {
        window.removeEventListener('keydown', listener)
        window.removeEventListener('click', clickListener)
      }
    }
  }, [open])

  return (
    <Tooltip open={open} text={open ? <img src={link} alt={asin} className="w-full h-full cursor-pointer" onClick={openKeepa}  /> : null} className="z-[1000] aspect-[800/250] !max-w-[75vw] !w-[64rem] !bg-surface-primary border border-border-primary drop-shadow-md" position="bottom">
      <button
        onClick={(e) => {
          e.stopPropagation()
          setOpen((old) => !old)
        }}
        className={[open ? "button-secondary" : "button-tertiary", "!p-1 w-8"].asClass}
      >
        <img src={KeepaIcon} alt="Keepa" className="w-6 h-6 rounded-full" />
      </button>
    </Tooltip>
  )
}

export default KeepaHistoryButton
