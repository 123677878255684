import api from 'api'
import { subscriptionPlans } from 'constants/subscriptions'
import useUserContext from 'contexts/User/useUserContext'
import { FC, useCallback, useEffect, useState } from 'react'
import { planFeatures } from '../subscriptions/plans'
import Logo from 'components/layout/Logo'
import { Widget } from '@typeform/embed-react'
import { createPortal } from 'react-dom'
import { modalContainer } from 'utils/modalContainer'
import firebase from 'config/firebase'
import Icon from 'assets/icons/iconset'

const SubscriptionSettings: FC = () => {
  const user = useUserContext()
  const [submitting, setSubmitting] = useState(false)
  const [period, setPeriod] = useState<'monthly' | 'yearly'>('monthly')
  const [surveyInProgress, setSurveyInProgress] = useState(false)

  const [selectedPlan, selectedPeriod] = Object.entries(user?.subscriptions || {}).filter(([, period]) => period)[0]

  const cancel = useCallback(async () => {
    if (submitting) return
    setSubmitting(true)
    await api
      .cancelSubscription()
      .then(() => window.location.reload())
      .finally(() => setSubmitting(false))
  }, [submitting])

  const submit = useCallback(
    async (plan: string, period: 'monthly' | 'yearly') => {
      if (selectedPlan && selectedPeriod) {
        if (plan === selectedPlan && period === selectedPeriod) {
          return setSurveyInProgress(true)
        }
      }
      if (submitting) return
      setSubmitting(true)
      await api
        .selectPlan(plan, period)
        .then((url) => {
          if (url) {
            window.open(url, '_self')
          }
        })
        .finally(() => setSubmitting(false))
    },
    [submitting, selectedPlan, selectedPeriod]
  )

  const openBillingPortal = useCallback(async () => {
    if (submitting) return
    setSubmitting(true)
    await api
      .openBillingPortal()
      .then((url) => {
        if (url) {
          window.open(url, '_self')
        }
      })
      .finally(() => setSubmitting(false))
  }, [submitting])

  useEffect(() => {
    if (selectedPeriod) {
      setPeriod(selectedPeriod as 'monthly' | 'yearly')
    }
  }, [selectedPeriod])

  return (
    <>
      {surveyInProgress &&
        createPortal(
          <div className="absolute inset-0 w-full h-full z-10 bg-white">
            <Icon name="X" onClick={() => setSurveyInProgress(false)} className="absolute cursor-pointer top-4 right-4 z-10 w-10 h-10 p-2 rounded-full bg-slate-50 hover:bg-slate-100 transition-colors" />
            <Widget
              onClose={() => setSurveyInProgress(false)}
              onSubmit={() => {
                cancel()
                setSurveyInProgress(false)
              }}
              shareGaInstance={firebase.app.options.measurementId}
              id="JleCHHdp"
              className="w-full h-full"
            />
          </div>,
          modalContainer
        )}
      <div className="p-4 border-b border-b-slate-200 w-full">
        <span className="text-base-900 font-medium text-base">Billing</span>
      </div>
      <div className="flex-cool overflow-y-auto items-start grow">
        <Logo className="w-40 [&>img]:!object-contain self-center pt-4" />
        <div className="h-max w-80 grid grid-cols-2 shrink gap-2 p-4">
          <button onClick={() => setPeriod('monthly')} className={period === 'monthly' ? 'button-primary h-min' : 'button-secondary h-min'}>
            Monthly
          </button>
          <button onClick={() => setPeriod('yearly')} className={period === 'yearly' ? 'button-primary h-min' : 'button-secondary h-min'}>
            Yearly
          </button>
        </div>
        <div className="grid xl:grid-cols-2 gap-4 px-4 pb-4 w-full">
          {subscriptionPlans.map((plan) => (
            <div key={plan.name} className="bg-white border border-gray-200 flex flex-col gap-1 grow rounded-xl drop-shadow-lg h-max">
              <div className="flex flex-col gap-0 p-4 bg-brand-primary text-center rounded-t-xl">
                <h2 className="text-white text-3xl font-bold">{plan.name}</h2>
                <p className="text-white text-xl font-semibold">{plan.isLimited ? 'Limited' : 'Unlimited'}</p>
              </div>
              <div className="flex items-end p-4 justify-center border-b border-gray-200">
                <span className="text-text-primary bottom-7 font-medium text-xl relative mr-1">$</span>
                <span className="text-brand-primary text-5xl font-semibold">{period === 'monthly' ? plan.price.monthly : plan.price.yearly}</span>
                <span className="text-text-primary  font-medium text-xl">{period === 'monthly' ? ' / Monthly' : ' / Yearly'}</span>
              </div>
              <ul className="p-4 font-medium text-text-primary text-lg border-b border-gray-200">
                {planFeatures[plan.id as keyof typeof planFeatures].map((feature) => (
                  <li key={feature} className="flex gap-2 mb-2">
                    <span className="font-medium text-xl">•</span>
                    <span className="font-semibold">{feature}</span>
                  </li>
                ))}
              </ul>
              <div className="p-4 [&>button]:w-full">
                <button className={plan.id === selectedPlan && period === selectedPeriod ? 'button-secondary' : 'button-primary'} onClick={() => submit(plan.id, period)} disabled={submitting}>
                  {plan.id === selectedPlan && period === selectedPeriod ? 'Cancel' : 'Select Plan'}
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="bg-slate-900 flex items-center justify-center w-full py-6 px-4">
          <button className="button-primary text-xl !px-10" onClick={openBillingPortal} disabled={submitting}>
            Manage Billing & View Invoices
          </button>
        </div>
      </div>
    </>
  )
}

export default SubscriptionSettings
