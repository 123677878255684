import { ColumnDef } from '@tanstack/react-table'
import { EntityWithKeepaStatColumns } from "types/keepaStats";

export const keepaStatsTableDef: ColumnDef<EntityWithKeepaStatColumns>[] = [
    {
        id: "newOfferCountsLatest",
        header: "Latest New Offer Count",
        meta: {
            filters: "number",
        }
    },
    {
        id: "newOfferCountsDays30",
        header: "New Offer Count (30 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "newOfferCountsDays90",
        header: "New Offer Count (90 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "newOfferCountsDays180",
        header: "New Offer Count (180 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "newOfferCountsDays365",
        header: "New Offer Count (365 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "salesRankLatest",
        header: "Latest Sales Rank",
        meta: {
            filters: "number",
        }
    },
    {
        id: "salesRankDays30",
        header: "Sales Rank (30 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "salesRankDays90",
        header: "Sales Rank (90 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "salesRankDays180",
        header: "Sales Rank (180 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "salesRankDays365",
        header: "Sales Rank (365 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "priceAmazonLatest",
        header: "Latest Amazon Price",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceAmazonDays30",
        header: "Amazon Price (30 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceAmazonDays90",
        header: "Amazon Price (90 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceAmazonDays180",
        header: "Amazon Price (180 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceAmazonDays365",
        header: "Amazon Price (365 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceNewLatest",
        header: "Latest New Price",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceNewDays30",
        header: "New Price (30 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceNewDays90",
        header: "New Price (90 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceNewDays180",
        header: "New Price (180 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceNewDays365",
        header: "New Price (365 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "buyBoxLatest",
        header: "Latest Buy Box",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "buyBoxDays30",
        header: "Buy Box (30 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "buyBoxDays90",
        header: "Buy Box (90 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "buyBoxDays180",
        header: "Buy Box (180 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "buyBoxDays365",
        header: "Buy Box (365 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "reviewsLatest",
        header: "Latest Reviews",
        meta: {
            filters: "number",
        }
    },
    {
        id: "reviewsDays30",
        header: "Reviews (30 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "reviewsDays90",
        header: "Reviews (90 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "reviewsDays180",
        header: "Reviews (180 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "reviewsDays365",
        header: "Reviews (365 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "ratingLatest",
        header: "Latest Rating",
        meta: {
            filters: "number",
        }
    },
    {
        id: "ratingDays30",
        header: "Rating (30 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "ratingDays90",
        header: "Rating (90 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "ratingDays180",
        header: "Rating (180 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "ratingDays365",
        header: "Rating (365 days)",
        meta: {
            filters: "number",
        }
    },
    {
        id: "priceFbaLatest",
        header: "Latest FBA Price",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbaDays30",
        header: "FBA Price (30 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbaDays90",
        header: "FBA Price (90 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbaDays180",
        header: "FBA Price (180 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbaDays365",
        header: "FBA Price (365 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbmLatest",
        header: "Latest FBM Price",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbmDays30",
        header: "FBM Price (30 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbmDays90",
        header: "FBM Price (90 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbmDays180",
        header: "FBM Price (180 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "priceFbmDays365",
        header: "FBM Price (365 days)",
        meta: {
            filters: "number",
            numberFormat: "price",
        }
    },
    {
        id: "inStockAmazonPercentage30",
        header: "In Stock Amazon Percentage (30 days)",
        meta: {
            filters: "number",
            numberFormat: "percentage",
        }
    },
    {
        id: "inStockAmazonPercentage90",
        header: "In Stock Amazon Percentage (90 days)",
        meta: {
            filters: "number",
            numberFormat: "percentage",
        }
    },
    {
        id: "inStockPercentage30",
        header: "In Stock Percentage (30 days)",
        meta: {
            filters: "number",
            numberFormat: "percentage",
        }
    },
    {
        id: "inStockPercentage90",
        header: "In Stock Percentage (90 days)",
        meta: {
            filters: "number",
            numberFormat: "percentage",
        }
    },
    {
        id: "inStockPercentage180",
        header: "In Stock Percentage (180 days)",
        meta: {
            filters: "number",
            numberFormat: "percentage",
        }
    },
    {
        id: "inStockPercentage365",
        header: "In Stock Percentage (365 days)",
        meta: {
            filters: "number",
            numberFormat: "percentage",
        }
    },
]