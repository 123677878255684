/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-extend-native */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/tagcolors.css'
import './index.css'
import Root from './Root'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import AuthProvider from './contexts/Auth/AuthProvider'
import TitleProvider from 'contexts/Title/TitleProvider'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { getExcelReader } from 'utils/excelUpload/worker'
import api from 'api'
// import { persistQueryClient, removeOldestQuery } from '@tanstack/react-query-persist-client'
// import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

declare global {
  var fpr: (event: string, data: Record<string, any>) => void
  var Supademo: (key: string, data: { variables: Record<string, string> }) => void
  var __ow: Record<string, any>
  var excelReader: Worker
}

window.excelReader = getExcelReader()

declare global {
  interface Array<T> {
    asClass: string
    dedupe: (keyFn?: (item: T) => string) => Array<T>
  }
}

Object.defineProperty(Array.prototype, 'asClass', {
  configurable: true,
  get() {
    return this.filter(Boolean).join(' ')
  },
})

Array.prototype.dedupe = function (keyFn?: (item: any) => string) {
  const seen = new Set()
  const key = keyFn || ((item) => item)
  const filtered = this.filter((item: any) => {
    const k = key(item)
    if (seen.has(k)) return false
    seen.add(k)
    return true
  })
  return filtered
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
    hydrate: {
      queries: {
        gcTime: 1000 * 60,
      }
    }
  },
})

window.onerror = function (message, source, lineno, colno, error) {
  // Log the error details to a remote server
  const errorData = {
    message: typeof message === 'string' ? message : message.toString(),
    source,
    lineno,
    colno,
    error: error ? error.stack : null,
  };

  api.log({type: 'windowError', message: errorData.message, data: errorData, stack: error?.stack}).catch(console.error)

  // Return true to prevent the default browser error handling
  return true;
};

window.onunhandledrejection = (e) => {
  const reason = e.reason;
  if (!reason) return;
  e.promise.then((data) => {
    api.log({type: 'unhandledRejection', message: reason as string, data}).catch(console.error)
  }).catch((error) => {
    api.log({type: 'unhandledRejection', message: reason as string, data: error}).catch(console.error)
  });
  // Application specific logging, throwing an error, or other logic here
};

/*const sessionStoragePersister = createSyncStoragePersister({
  storage: window.sessionStorage,
  retry: removeOldestQuery,
})

persistQueryClient({
  queryClient,
  persister: sessionStoragePersister,
})*/

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <TitleProvider>
        <AuthProvider>
          <BrowserRouter>
            <Root />
          </BrowserRouter>
        </AuthProvider>
      </TitleProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
