import { FC, useCallback } from 'react'
import { columnDef } from './tableDef'
import Table from 'components/tables/Table'
import ContentLayout from 'components/layout/Content'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { useMemo } from 'react'
import { IApiFilter } from 'types/tableFiltering'
import warehouseApi from 'api/warehouse'
import { IWarehouseInboundShipment } from 'types/warehouse'
import Item from './Item'
import useSupplierNames from 'hooks/useSupplierNames'
import withFiltering from 'contexts/Filter/wrapper'
import { Row } from '@tanstack/react-table'
import useSelect from 'hooks/useSelect'
import useBulkDelete from 'hooks/useBulkDelete'

const queryFn = async ({ pageParam: page, queryKey }: { pageParam: number; queryKey: [string, IApiFilter] }) => {
  return warehouseApi.getWarehouseInboundShipments({ page, ...queryKey[1] })
}

const dashboardQueryFn = async () => {
  return warehouseApi.getWarehouseInboundDashboard()
}

const WarehouseInboundPage: FC = () => {
  const { supplierNames } = useSupplierNames()
  const { search, ordering, opFilters: filters } = useFilteringContext()
  const select = useSelect()

  const [onDelete, deleting, AreYouSureDeleteModal] = useBulkDelete(select, warehouseApi.deleteWarehouseInboundShipments, {
    header: 'Delete Shipments',
    description: 'Are you sure you want to delete these Shipments?',
  })

  const queryKey = useMemo(
    () => [
      'warehouse-inbound',
      {
        search,
        ordering,
        filters,
      },
    ],
    [search, ordering, filters]
  )

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: queryKey as [string, IApiFilter],
    queryFn: (params) => queryFn(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next || undefined,
  })

  const { data: dashboard } = useQuery({
    queryKey: ['warehouse-inbound-dashboard'],
    queryFn: dashboardQueryFn,
  })

  const renderRow = useCallback((row: Row<IWarehouseInboundShipment>) => <Item row={row} />, [])

  const bulkInProgress = deleting
  const canDoBulk = select.selected.length > 0 || select.allSelected

  return (
    <ContentLayout
      wrapperClass="gap-4 !bg-surface-light !border-none !flex flex-col-reverse lg:flex-row"
      underSearch={
        canDoBulk ? (
          <div className="flex gap-4 items-center">
            <button className="button-destructive w-max" onClick={onDelete} disabled={bulkInProgress}>
              Delete
            </button>
          </div>
        ) : undefined
      }
    >
      <AreYouSureDeleteModal />
      <Table
        name="warehouse-inbound"
        columns={columnDef}
        items={data?.pages.flatMap((p) => p.items)}
        renderRow={renderRow}
        loading={!data}
        loadingNext={isFetchingNextPage}
        onBottom={() => !isFetchingNextPage && hasNextPage && fetchNextPage()}
        select={select}
        extra={{ supplierNames }}
      />
      <div className="flex lg:flex-col gap-4 w-full lg:w-[12rem]">
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary">
          <span className="text-text-secondary text-sm">Total Shipments</span>
          <span className="text-text-primary text-xl">{dashboard?.shipments ?? '...'}</span>
        </div>
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary">
          <span className="text-text-secondary text-sm">Total Sellable Units</span>
          <span className="text-text-primary text-xl">{dashboard?.units ?? '...'}</span>
        </div>
      </div>
    </ContentLayout>
  )
}

export default withFiltering(WarehouseInboundPage, 'warehouse-in')
