import { FC } from "react"

interface props {
    checked: boolean
    semichecked?: boolean
}

const Checkbox: FC<props> = ({checked, semichecked}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className="cursor-pointer group" xmlns="http://www.w3.org/2000/svg">
      <rect x="2.5" y="2.5" width="15" height="15" rx="3.5"  className={["transition-all group-hover:fill-brand-hover", checked ? "fill-brand-primary" : "fill-surface-primary"].asClass}/>
      <rect x="2.5" y="2.5" width="15" height="15" rx="3.5" className="transition-all stroke-slate-950"/>
      {semichecked ?
       <rect x="5" y="9.5" width="10" height="1" rx="1" fill="white" /> :
       <path d="M14.7655 7.64052L8.76552 13.6405C8.73069 13.6754 8.68934 13.703 8.64381 13.7219C8.59829 13.7408 8.54949 13.7505 8.50021 13.7505C8.45093 13.7505 8.40213 13.7408 8.35661 13.7219C8.31108 13.703 8.26972 13.6754 8.2349 13.6405L5.6099 11.0155C5.53953 10.9452 5.5 10.8497 5.5 10.7502C5.5 10.6507 5.53953 10.5553 5.6099 10.4849C5.68026 10.4145 5.7757 10.375 5.87521 10.375C5.97472 10.375 6.07016 10.4145 6.14052 10.4849L8.50021 12.8451L14.2349 7.1099C14.3053 7.03953 14.4007 7 14.5002 7C14.5997 7 14.6952 7.03953 14.7655 7.1099C14.8359 7.18026 14.8754 7.2757 14.8754 7.37521C14.8754 7.47472 14.8359 7.57016 14.7655 7.64052Z" fill="white"/>}
    </svg>
  )
}

export default Checkbox
