import axios, { AxiosInstance } from "axios"
import baseAxios from "./axios"

class CDNApi {
  axios: AxiosInstance
  constructor() {
    this.axios = axios.create({
        ...baseAxios.defaults,
        baseURL: baseAxios.defaults.baseURL?.split("/api")[0] + "/cdn"
    })
  }
  
  asinImageUrl = (asin: string, params: { crop?: boolean; size?: number }) => {
    const url = `/amazon/product-image/${asin}`;
    const base = this.axios.defaults.baseURL
    const sp = new URLSearchParams();
    if (params.crop) {
      sp.append("crop", "true");
    }
    if (params.size) {
      sp.append("size", params.size.toString());
    }
    return `${base}${url}?${sp.toString()}`;
  };
}

const cdnApi = new CDNApi()

export default cdnApi
