import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import purchaseOrdersApi from 'api/purchaseOrders'
import Empty from 'components/Empty'
import FullLoader from 'components/loaders/FullLoader'
import useTitle from 'contexts/Title/useTitle'
import { FC, Fragment, useCallback, useMemo } from 'react'
import PurchaseOrder from '../components/PurchaseOrder'
import usePrepCosts from 'hooks/usePrepCosts'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { useBasePricePreferences } from 'hooks/useBasePricePreferences'
import useAwaitableModal from 'hooks/useAwaitableModal'
import UpdatePreferencesModal from '../PurchaseOrder/components/UpdatePreferencesModal'

const queryFn = ({ pageParam = 1 }) => purchaseOrdersApi.getPurchaseOrders({ page: pageParam, status: 'open' })

const OpenPurchaseOrdersPage: FC = () => {
  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery({
    queryKey: ['openPurchaseOrders'],
    queryFn: queryFn,
    initialPageParam: 1,
    getNextPageParam: (last) => last.next || undefined,
  })

  const onBottom = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  const scrollRef = useBottomScrollListener<HTMLDivElement>(onBottom, { triggerOnNoScroll: true, offset: 1000, debounce: 500, debounceOptions: { leading: true } })
  const {prepCosts} = usePrepCosts()
  const preferences = useBasePricePreferences()
  const basePrice = useMemo(() => preferences?.purchaseOrders || 'latest', [preferences?.purchaseOrders])
  const [changePreferences, UpdatePreferences] = useAwaitableModal(UpdatePreferencesModal, undefined)

  const qc = useQueryClient()

  const updateInvoice = useCallback((id: string, invoice: string | null) => {
    qc.setQueryData(['openPurchaseOrders'], (old: typeof data) => {
      if (!old) return old
      return {
        ...old,
        pages: old.pages.map((page) => ({
          ...page,
          purchaseOrders: page.purchaseOrders.map((po) => {
            if (po.id === id) {
              return {
                ...po,
                invoice,
              }
            }
            return po
          }),
        })),
      }
    })
  }, [qc])

  const onDelete = useCallback((id: string) => {
    qc.setQueryData(['openPurchaseOrders'], (old: typeof data) => {
      if (!old) return old
      return {
        ...old,
        pages: old.pages.map((page) => ({
          ...page,
          purchaseOrders: page.purchaseOrders.filter((po) => po.id !== id),
        })),
      }
    })
  }, [qc])

  useTitle('Purchase Orders Confirmation')

  return (
    <div className="w-full h-full overflow-y-auto p-4 bg-surface-light" ref={scrollRef}>
      <UpdatePreferences />
      <div className="flex justify-end gap-4 pb-4">
        <button className="button-secondary" onClick={changePreferences}>
          Preferences
        </button>
      </div>
      {!data ? (
        <FullLoader />
      ) : data.pages.at(0)?.purchaseOrders?.length === 0 ? (
        <Empty text="No purchase orders" />
      ) : (
        <div className="flex flex-col grow w-full gap-4">
          {data?.pages.map((page, i) => (
            <Fragment key={i}>
              {page.purchaseOrders.map((po) => (
                <PurchaseOrder key={po.id} purchaseOrder={po} basePrice={basePrice} shippingCostDef={data.pages.at(-1)?.shippingCostDef} prepCosts={prepCosts} updateInvoice={updateInvoice} onDelete={() => onDelete(po.id)} />
              ))}
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default OpenPurchaseOrdersPage
