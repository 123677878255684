import useTitle from 'contexts/Title/useTitle'
import { FC } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'

const steps = [
  {
    id: '1',
    name: 'Software Overview & Functionality',
    v: 'CRtfyxZhY6A',
  },
  {
    id: '2',
    name: 'How to Find Wholesale Suppliers & Create A Leads List',
    v: 'fofqvEiANUY',
  },
  {
    id: '3',
    name: 'How to Add a Supplier to the Software',
    v: 'OZlHySwciIA',
  },
  {
    id: '4',
    name: 'Reviewing Supplier Catalogs',
    v: 'YcJGeDF2aLw',
  },
  {
    id: '5',
    name: 'Uploading Supplier Catalog, Filter Presets, & Columns',
    v: 'hV-DT9QI1vA',
  },
  {
    id: '6',
    name: 'Supply Chain Scanner Overview',
    v: 'WnVbMVamQmg',
  },
  {
    id: '7',
    name: 'How to Source The Supply Chain Scanner',
    v: 'N2OwgWfZrV8',
  },
  {
    id: '8',
    name: 'How to Storefront Stalk On Third Party Profits',
    v: 'GJfkbYfmqtc',
  },
  {
    id: '9',
    name: 'How to Delete Previous Scans',
    v: 'vpWw3g01tKk',
  },
  {
    id: '10',
    name: 'How to Add Products to Working Purchase Orders',
    v: 'AahkMuOhvoc',
  },
  {
    id: '11',
    name: 'What is the Master Product Folder',
    v: 'loYF8fCmDWI',
  },
  {
    id: '12',
    name: 'How to Source in the Product Folder & Add To Purchase Orders',
    v: 'HmF2ot7Vv24',
  },
  {
    id: '13',
    name: 'How to Restock Products & Add Into Purchase Orders',
    v: 'CvNV5sg_c5w',
  },
  {
    id: '14',
    name: 'Review Purchase Orders, Submit & Create Shipments',
    v: '04OuNPdAuTU',
  },
  {
    id: '15',
    name: 'How to Manage Your Inventory',
    v: 'v2fQ-wXp9N0',
  },
  {
    id: '16',
    name: 'How to Get Support from Third Party Profits',
    v: 'IJiFD9jdy3w',
  },
]

const TutorialPage: FC = () => {
  const { step: stepId } = useParams() as { step?: string }
  const navigate = useNavigate()

  useTitle(['Software Training', stepId ? `Lesson ${stepId}` : ''].filter(Boolean).join(' - '))

  if (!stepId) {
    return <Navigate to="/app/tutorial/1" replace />
  }

  return (
    <div className="w-full h-full p-4 flex-cool gap-4">
      <div className="w-full overflow-hidden flex flex-col lg:flex-row gap-4">
        <div className="bg-white rounded-xl flex-cool max-h-full overflow-hidden grow aspect-video h-max">
          <iframe
            className="w-full h-full"
            src={`https://www.youtube.com/embed/${steps[+stepId - 1].v}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="bg-white rounded-xl flex-cool divide-y divide-y-base-200 overflow-hidden h-full !max-w-md">
          <div className="p-4 flex items-center justify-between">
            <span className="text-base-900 font-medium text-base">Lessons</span>
            <span>{steps.length} videos</span>
          </div>
          <div className="flex-cool divide-y divide-y-base-200 overflow-y-auto">
            {steps.map((step) => (
              <Link
                to={'../' + step.id}
                relative="path"
                key={step.id}
                className={['flex items-center gap-3 group cursor-pointer p-4 hover:bg-base-50', +step.id === +stepId ? 'text-brand-primary bg-brand-disabled' : 'text-base-900'].join(' ')}
                onClick={() => navigate(`/app/tutorial/${step.id}`)}
              >
                <span className="font-semibold text-base w-24 shrink-0">Lesson {step.id}:</span>
                <span className="text-base">{step.name}</span>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TutorialPage
