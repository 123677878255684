import { useQuery, useQueryClient } from '@tanstack/react-query'
import housedAsinsApi from 'api/housedAsins'
import { CloseIcon } from 'assets/icons'
import Empty from 'components/Empty'
import Modal from 'components/Modal'
import IconButton from 'components/buttons/IconButton'
import FullLoader from 'components/loaders/FullLoader'
import AreYouSure from 'components/modals/AreYouSure'
import useAwaitableModal, { AwaitableModal } from 'hooks/useAwaitableModal'
import { useState } from 'react'

const ListCatalogs: AwaitableModal<boolean> = ({ open, onCancel, resolve: onResolve }) => {
  const [deleted, setDeleted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [areYouSure, AreYouSureModal] = useAwaitableModal(AreYouSure, {})

  const {data: catalogs} = useQuery({
    queryKey: ["master-catalog-uploads"],
    queryFn: housedAsinsApi.getUploadedCatalogs,
    enabled: open,
  })

  const qc = useQueryClient()

  const onDelete = (catalog: string) => {
    if (submitting) return
    setSubmitting(true)
    housedAsinsApi.deleteUploadedCatalog(catalog).then(() => {
      qc.setQueryData(["master-catalog-uploads"], (c: string[] | undefined) => c?.filter((r) => r !== catalog))
      setDeleted(true)
      setSubmitting(false)
    })
  }

  const resolve = () => {
    onResolve(deleted)
  }

  return (
    <Modal open={open} close={resolve}>
      <AreYouSureModal />
      <div className="bg-white rounded-xl flex-col overflow-y-hidden flex divide-y divide-y-slate-200 relative max-h-[calc(100vh-64px)] h-full w-[32rem] max-w-[calc(100vw-4rem)]">
        <header className="!mb-0 flex items-center justify-between">
          <span>Previous Scans</span>
          <CloseIcon onClick={onCancel} className="rounded-full hover:bg-slate-200 p-2 cursor-pointer w-10 h-10" />
        </header>
        <div className="grow w-full overflow-y-auto">
          <div className="flex flex-col p-6 gap-4">
            {catalogs === undefined ? (
              <FullLoader />
            ) : !catalogs.length ? (
              <Empty text="No Catalogs Uploaded" />
            ) : (
              <table className="w-full h-full border border-slate-200 rounded-2xl [&_td]:!p-3 [&_th]:!p-3 [&_th]:border">
                <thead>
                  <tr>
                    <td>File</td>
                    <td>Uploaded At</td>
                    <td>Actions</td>
                  </tr>
                </thead>
                <tbody>
                  {catalogs.slice(0).sort((a, b) => b.split("_").at(-1)?.localeCompare(a.split("_").at(-1) || "") || 0).map((r: any) => {
                    const split = r.split('_')
                    const fileName = split.slice(0, split.length - 1).join('_')
                    if (split.length < 2) return null
                    const date = split[split.length - 1]
                    return (
                      <tr key={r}>
                        <td>{fileName}</td>
                        <td>{new Date(date).toLocaleString()}</td>
                        <td>
                          <div className="flex gap-2">
                            <IconButton name="trash" onClick={() => areYouSure().then(() => onDelete(r))} />
                            <IconButton name="export" className="rotate-45" onClick={() => housedAsinsApi.exportMasterCatalog({ catalog: r })} />
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            )}
          </div>
        </div>
        <footer className="flex gap-10 items-center p-6 justify-between">
          <button className="button-dark" onClick={onCancel}>
            Close
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default ListCatalogs
