import Modal from 'components/Modal'
import { AwaitableModal } from 'hooks/useAwaitableModal'
import AsinPicture from './img'

const PreviewImageModal: AwaitableModal<{}, { asin: string }> = ({ initialData: { asin }, onCancel, open }) => {

  return (
    <Modal open={open} close={onCancel}>
      <AsinPicture asin={asin} size={500} className="rounded-xl drop-shadow-xl p-4 bg-white" />
    </Modal>
  )
}

export default PreviewImageModal
