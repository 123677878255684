import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import Tooltip from './Tooltip'

interface props {
  children: React.ReactNode
  anchorEl: ReactNode
  top?: boolean
  wrapperClass?: string
}

const Dropdown: FC<props> = ({ anchorEl, children, top: _top, wrapperClass }) => {
  const [show, setShow] = useState<boolean>(false)
  const listener = useRef<() => void>()

  useEffect(() => {
    const handler = () => {
      setShow(false)
    }

    document.addEventListener('hideSelector', handler)

    listener.current?.()
    listener.current = () => document.removeEventListener('hideSelector', handler)

    return listener.current
  }, [show])

  useEffect(() => {
    const listener = () => setShow(false)

    window.addEventListener('click', listener)

    return () => window.removeEventListener('click', listener)
  }, [show])

  return (
    <Tooltip open={show} interactive className="bg-white z-[100000000000] !min-w-max !overflow-hidden !p-0 text-base" position="left-start" text={show && <div className={wrapperClass}>{children}</div>}>
      <button
        className="selector"
        children={anchorEl}
        onClick={(e) => {
          e.stopPropagation()
          setShow((old) => {
            if (old) return false
            listener.current?.()

            const event = new CustomEvent('hideSelector')
            document.dispatchEvent(event)

            return true
          })
        }}
      />
    </Tooltip>
  )
}

export default Dropdown
