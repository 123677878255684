import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import suppliersApi from 'api/suppliers'
import { SupplierLead } from 'types/supplierLeads'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import { IApiFilter } from 'types/tableFiltering'

export interface IConnectedClient {
  id: string
  client: string
}

const queryFn = async ({pageParam: page, queryKey}: {pageParam: number, queryKey: [string, IApiFilter]}) => {
  return suppliersApi.getLeads({page, ...queryKey[1]})
}

const useSupplierLeads = () => {
  const filter = useFilteringContext()
  const {search, ordering, opFilters: filters} = filter

  const queryKey = useMemo(() => (
    [
    'supplier-leads',
    {
      search,
      ordering,
      filters
    }
    ] as [string, IApiFilter]
  ), [search, ordering, filters])

  const {data, refetch, fetchNextPage, isFetchingNextPage, hasNextPage: hasMore} = useInfiniteQuery(
    {
      queryKey: queryKey,
      queryFn,
      initialPageParam: 1,
      getNextPageParam: (lastPage) => lastPage.next || undefined,
    }
  )

  const loadingNextPage = isFetchingNextPage

  const items = useMemo(() => data ? [...data.pages.flatMap((p) => p.leads)] : undefined, [data])

  const qc = useQueryClient()

  const setItems: Dispatch<SetStateAction<SupplierLead[] | undefined>> = useCallback((action) => {
    qc.setQueryData(queryKey, (old: typeof data) => {
      if (!old) return old
      if (action instanceof Function){
        return {
          ...old,
          pages: old.pages.map((p) => ({
            ...p,
            leads: action(p.leads)
          }))
        }
      } if (!action) {
        refetch()
      } else {
        return {
          ...old,
          pages: old.pages.map((p, i) => ({
            ...p,
            leads: action[i]
          }))
        }
      }
    })
  }, [queryKey, refetch, qc])

  return {
    items,
    setItems,
    loadingNextPage,
    fetchNextPage: isFetchingNextPage || !hasMore ? undefined : fetchNextPage,
    hasMore,
  }
}

export default useSupplierLeads
