import { FC, useCallback } from 'react'
import { columnDef } from './tableDef'
import Table from 'components/tables/Table'
import ContentLayout from 'components/layout/Content'
import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import { useMemo } from 'react'
import { IApiFilter } from 'types/tableFiltering'
import warehouseApi from 'api/warehouse'
import { IWarehouseOutboundShipment } from 'types/warehouse'
import Item from './Item'
import withFiltering from 'contexts/Filter/wrapper'
import { usNumber } from 'utils/formatting'
import { useOutboundSync } from 'contexts/OutboundSync'
import Loader from 'components/loaders/Loader'
import { Row } from '@tanstack/react-table'

const queryFn = async ({ pageParam: page, queryKey }: { pageParam: number; queryKey: [string, IApiFilter] }) => {
  return warehouseApi.getWarehouseOutboundItems({ page, ...queryKey[1] })
}

const dashboardQueryFn = async () => {
  return warehouseApi.getWarehouseOutboundDashboard()
}

const WarehouseOutboundPage: FC = () => {
  const { synced, syncedUntil } = useOutboundSync()
  const { search, ordering, opFilters: filters } = useFilteringContext()

  const queryKey = useMemo(
    () => [
      'warehouse-outbound',
      {
        search,
        ordering,
        filters,
      },
    ],
    [search, ordering, filters]
  )

  const { data, isLoading, fetchNextPage, isFetchingNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: queryKey as [string, IApiFilter],
    queryFn: (params) => queryFn(params),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.next || undefined,
    enabled: synced,
    refetchOnMount: false,
  })

  const { data: dashboard } = useQuery({
    queryKey: ['warehouse-outbound-dashboard'],
    queryFn: dashboardQueryFn,
    enabled: synced,
    refetchOnMount: false,
  })

  const renderRow = useCallback((row: Row<IWarehouseOutboundShipment>) => <Item key={row.original.shipmentId} row={row} />, [])

  return (
    <ContentLayout wrapperClass="gap-4 !bg-surface-light !border-none !flex flex-col-reverse relative lg:flex-row">
      {!synced && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 z-[1000] p-4 flex items-center justify-center">
          <div className="bg-white rounded-lg flex flex-col items-center justify-center p-4">
            <Loader />
            <div className="text-center mt-3 font-medium">Syncing FBA Shipments Data... {syncedUntil ? `Synced until: ${new Date(syncedUntil).toDateString()}` : ''}</div>
            <div className="text-center mt-1 text-slate-500">This is a long running operation, don't close this page</div>
          </div>
        </div>
      )}
      <div className="!overflow-y-auto !bg-surface-light !border-none !flex !flex-col grow">
        <div className="flex flex-col w-full h-full bg-surface-primary !rounded-lg gap-4 [&>div:nth-child(2)]:border [&>div:nth-child(2)]:border-border-primary [&>div:nth-child(2)]:rounded-lg [&>div:nth-child(2)]:min-h-full">
          <Table
            name="warehouse-outbound"
            columns={columnDef}
            items={data?.pages.flatMap((p) => p.items)}
            renderRow={renderRow}
            loading={isLoading && !data}
            loadingNext={isFetchingNextPage}
            onBottom={() => !isFetchingNextPage && hasNextPage && fetchNextPage()}
          />
        </div>
      </div>
      <div className="flex lg:flex-col gap-4 w-full lg:w-[12rem]">
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary">
          <span className="text-text-secondary text-sm">Total Shipments</span>
          <span className="text-text-primary text-xl">{usNumber(dashboard?.shipments, 0)}</span>
        </div>
        <div className="flex flex-col gap-1 p-4 rounded-lg border border-border-primary bg-surface-primary">
          <span className="text-text-secondary text-sm">Total Units</span>
          <span className="text-text-primary text-xl">{usNumber(dashboard?.units, 0)}</span>
        </div>
      </div>
    </ContentLayout>
  )
}

export default withFiltering(WarehouseOutboundPage, 'warehouse-out')
