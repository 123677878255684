import { Row } from '@tanstack/react-table'
import TR from 'components/tables/TR'
import TD from 'components/tables/TD'
import { FC } from 'react'
import { RowRenderer } from 'types/tables'
import { IWarehouseOutboundShipment } from 'types/warehouse'
import { usNumber } from 'utils/formatting'

interface props {
  row: Row<IWarehouseOutboundShipment>
}

const Item: FC<props> = ({ row }) => {
  const item = row.original

  const cellRenderers: RowRenderer<IWarehouseOutboundShipment> = {
    dateIn: (cell) => (
      <TD key={cell.id} cell={cell}>
        {new Date(item.dateIn).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
      </TD>
    ),
    shipmentId: (cell) => (
      <TD key={cell.id} cell={cell}>
        <input type="text" value={item.shipmentId} readOnly />
      </TD>
    ),
    asins: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.asins, 0)}
      </TD>
    ),
    units: (cell) => (
      <TD key={cell.id} cell={cell}>
        {usNumber(item.units, 0)}
      </TD>
    ),
    shipmentStatus: (cell) => (
      <TD key={cell.id} cell={cell}>
        {item.shipmentStatus}
      </TD>
    ),
    dateOut: (cell) => (
      <TD key={cell.id} cell={cell}>
        {new Date(item.dateOut).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
      </TD>
    ),
  }
  return <TR>{row.getVisibleCells().map((cell) => cellRenderers[cell.column.id]?.(cell))}</TR>
}

export default Item
