import { useInfiniteQuery } from '@tanstack/react-query'
import housedAsinsApi from 'api/housedAsins'
import Table from 'components/tables/Table'
import useSelect from 'hooks/useSelect'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import columnDef from './tableDef'
import { HousedAsin } from 'types/housedAsins'
import Item from './Item'
import withFiltering from 'contexts/Filter/wrapper'
import useFilteringContext from 'contexts/Filter/useFilteringContext'
import Loader from 'components/loaders/Loader'
import Icon from 'assets/icons/iconset'
import { CloseIcon } from 'assets/icons'
import { IApiFilter } from 'types/tableFiltering'
import useAwaitableModal from 'hooks/useAwaitableModal'
import CreateWorkingPO from 'pages/app/housedAsins/Housed/components/CreateWorkingPO'
import useSupplierNames from 'hooks/useSupplierNames'
import { Row } from '@tanstack/react-table'

interface props {
  open: boolean
  setOpen: (open: boolean) => void
  asins: string[]
}

const queryFn = async ({ queryKey, pageParam }: { queryKey: string[]; pageParam: number }) => {
  const { filters, ordering, search } = JSON.parse(queryKey[3]) as IApiFilter
  const asins = queryKey[2] ? queryKey[2].split(',') : []
  return housedAsinsApi.getHoused({
    page: pageParam,
    ordering,
    search,
    filters: {
      ...filters,
      ...(asins.length > 0 && {
        asin: {
          o: 'NOT IN',
          v: asins,
        },
      }),
    },
  })
}

const AddAsin: FC<props> = ({ open, setOpen, asins }) => {
  const select = useSelect()
  const { search, opFilters, ordering, setSearch, searching } = useFilteringContext()
  const filters = useMemo(() => JSON.stringify({ search, filters: opFilters, ordering }), [search, opFilters, ordering])
  const [submitting] = useState(false)
  const { supplierNames } = useSupplierNames()

  const { data, isFetchingNextPage, fetchNextPage, hasNextPage } = useInfiniteQuery({
    queryKey: ['master-catalog', 'working-po', asins.join(','), filters],
    queryFn,
    getNextPageParam: (lastPage) => lastPage.next || undefined,
    initialPageParam: 1,
  })

  const items = useMemo(() => data?.pages.flatMap((p) => p.items), [data?.pages])

  const [createWorkingPO, CreateWorkingPOModal] = useAwaitableModal(CreateWorkingPO, [])

  const anyTimeSelected = useRef<Record<string, HousedAsin>>({})

  useEffect(() => {
    anyTimeSelected.current = {
      ...anyTimeSelected.current,
      ...Object.fromEntries(select.selected.map((id) => [id, items?.find((i) => i.id === id)!])),
    }
  }, [select, items])

  useEffect(() => {
    if (open) {
      select.clear()
    }
  }, [open])

  const renderRow = useCallback((row: Row<HousedAsin>) => <Item key={row.original.id} row={row} />, [])

  const canDoBulk = select.selected.length > 0 || select.allSelected

  const onCreateWorkingPO = useCallback(() => {
    const items = select.selected.map((id) => anyTimeSelected.current[id])
    createWorkingPO(items)
  }, [createWorkingPO, select])

  return (
    <div
      className="w-full h-full fixed inset-0 flex justify-end z-[100000]"
      style={{
        transform: `translateX(${open ? 0 : '100%'})`,
        transition: 'transform 0.5s',
        pointerEvents: open ? 'auto' : 'none',
      }}
      onClick={() => setOpen(false)}
    >
      <CreateWorkingPOModal />
      <div onClick={(e) => e.stopPropagation()} className="w-full max-w-[min(95%,40rem)] relative h-full bg-white drop-shadow-xl overflow-hidden">
        <CloseIcon className="absolute top-4 right-4 cursor-pointer z-10" onClick={() => setOpen(false)} />
        <div className="flex flex-col min-h-full gap-6 p-4 h-full">
          <div className="flex flex-col gap-1">
            <span className="text-text-secondary text-sm mt-2">Please enter new ASINS in Housed ASINS.</span>
            <span className="text-text-secondary text-sm">Note: Profit is calculated based on current Amazon price.</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="input-box relative w-full">
              <input type="text" value={search} onChange={(e) => setSearch(e.currentTarget.value)} placeholder="Search by UPC / ASIN..." className="!px-8 [&:not(:hover)]:!border-border-secondary" />
              <div className="flex absolute w-full items-center justify-between h-10 px-2 pointer-events-none">
                <Icon name="MagnifyingGlass" className="w-5 h-5" />
                {searching && <Loader size={20} />}
              </div>
            </div>
          </div>
          <div className="border border-border-primary bg-surface-primary rounded-lg h-full overflow-hidden">
            <Table name="master-catalog-add-working" columns={columnDef} initialPinState={{left: ["image", "asin"]}} renderRow={renderRow} select={select} items={items} loadingNext={isFetchingNextPage} onBottom={isFetchingNextPage || !hasNextPage ? undefined : fetchNextPage} extra={{supplierNames}} />
          </div>
          <div className="flex justify-end w-full">
            <button
              className="button-primary"
              disabled={submitting || !canDoBulk}
              onClick={() => {
                setOpen(false)
                onCreateWorkingPO()
              }}
            >
              Add to Working POs
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withFiltering(AddAsin, "master-catalog-add-working")
